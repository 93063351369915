.content {
  width: 100%;
  height: 64px;
  background: #213189;
  padding: 0 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 99;
}
.content .btn {
  width: 100px;
  height: 40px;
  font-family: Common-Bold;
  font-size: 16px;
}
.content .title {
  min-width: 560px;
  flex: 1 1;
  text-align: center;
}
.content .title .text {
  font-size: 16px;
  font-family: Common-Bold;
  color: rgba(255, 255, 255, 0.9);
}
.content .title .email {
  font-size: 16px;
  font-family: Common-Bold;
  color: #F17E00;
  margin-left: auto;
}
