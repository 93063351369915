.history {
  max-width: 1200px;
  background: #1f212f;
  border-radius: 2px;
  margin: auto;
  margin-top: 34px;
}
.history .tableWrap {
  display: flex;
  margin-top: 184px;
  position: relative;
}
.history .tableWrap :global .ant-table-thead > tr > th {
  padding: 6px 14px;
  height: 57px;
}
.history .tableWrap :global .ant-table-tbody > tr > td {
  padding: 16px 12px;
  height: 57px;
}
.history .tableWrap :global .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  background: transparent;
  pointer-events: none;
}
.history .tableWrap :global .ant-table-thead > tr > th,
.history .tableWrap :global .ant-table-sticky-holder {
  background-color: rgba(255, 255, 255, 0.05);
}
.history .leftTab {
  width: 200px;
  height: 800px;
  background: #2a2d41;
}
.history .leftTab .tabItem {
  height: 85px;
  display: flex;
  align-items: center;
  font-family: Common-Medium;
  color: #dfdfe2;
  font-size: 18px;
  cursor: pointer;
}
.history .leftTab .tabItem.active {
  color: #f17e00;
  background: #1f212f;
}
.history .leftTab .tabItem .tabIcon {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  margin-left: 25px;
}
.history .tab {
  height: 64px;
}
.history .tab :global .ant-tabs-tab {
  margin: 0;
  width: 180px;
}
.history .tab :global .ant-tabs-tab-btn {
  padding-top: 5px;
  width: 100%;
  height: 64px;
  font-size: 18px;
  font-family: Common-Regular;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
}
.history .tab :global .ant-tabs-tab-btn:hover {
  color: #fff;
}
.history .tab :global .ant-tabs-tab-active {
  background-color: #26283a;
}
.history .tab :global .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
}
.history .historyArea {
  padding: 25px 24px 32px;
  min-height: 600px;
  background: #26283a;
}
.history .historyArea .loadMore {
  display: flex;
  justify-content: center;
  color: #f17e00;
  cursor: pointer;
}
.history .historyArea .loadMore:hover {
  color: #a45907;
}
.history .historyArea .table {
  margin-bottom: 30px;
}
.history .historyArea .yieldTableTitle :global .ant-radio-group {
  background: rgba(255, 255, 255, 0.0529);
  border-radius: 15px;
}
.history .historyArea .yieldTableTitle :global .ant-radio-group .ant-radio-button-wrapper {
  width: 142px;
  text-align: center;
  border: none;
  transition: unset;
}
.history .historyArea .yieldTableTitle :global .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}
.history .historyArea .yieldTableTitle :global .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #F17E00;
  border-radius: 15px;
  color: #fff;
}
.history .historyArea .yieldTableTitle :global .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
}
.history .historyArea .yieldTableTitle :global .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: unset;
}
.history .action {
  color: #F17E00;
  cursor: pointer;
}
