.base {
  padding: 21px 32px 32px 33px;
  height: 200px;
  background: #1F212F;
}
.assetSituational {
  composes: base;
}
.assetSituational .top {
  justify-content: space-between;
}
.divider {
  width: 100%;
  height: 1px;
  margin: 24px 0;
  background: rgba(255, 255, 255, 0.2);
}
.strategy {
  max-width: 1200px;
  min-height: 1200px;
  background: #1f212f;
  border-radius: 2px;
  padding: 32px 24px;
  margin: auto;
}
.strategy .strategyList {
  display: flex;
  flex-wrap: wrap;
}
.strategy .strategyListItem:nth-child(3n) {
  margin-right: 0px !important;
}
.strategy .strategyListItem {
  width: 32%;
  margin-right: 22px;
}
.strategy .strategyItem {
  position: relative;
  width: 32%;
  height: 354px;
  background: #1f212f;
  border-radius: 2px;
  margin-bottom: 24px;
}
.strategy .fundtitle {
  font-size: 18px;
  font-family: Common-Medium;
  font-weight: 500;
  color: #ffffff;
  line-height: 26px;
  margin-bottom: 7px;
  text-align: center;
}
.strategy .fundsubtitle {
  height: 24px;
  line-height: 24px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 15px;
  font-size: 12px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  display: inline-block;
  margin: auto;
  width: 128px;
}
.strategy .item {
  background: #1f212f;
  padding: 24px;
  width: 100%;
}
.strategy .item .top {
  height: 60px;
  background: transparent;
  color: #fff;
}
.strategy .item button {
  margin-top: 20px;
  width: 100%;
  height: 40px;
}
.strategy .item .green {
  font-size: 28px;
  font-family: Common-Medium;
  font-weight: 500;
  color: #09d687;
  line-height: 24px;
  margin-bottom: 8px;
}
.strategy .item .red {
  font-size: 28px;
  font-family: Common-Medium;
  font-weight: 500;
  color: #f5472a;
  line-height: 24px;
}
.strategy .item .common {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 22px;
}
.strategy .item .common2 {
  font-size: 12px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 18px;
  margin-bottom: 4px;
}
.strategy .item .qushi {
  height: 140px;
}
.strategy .item .overview {
  margin-bottom: 10px;
}
.strategy .item .highLight {
  font-size: 16px;
  font-family: Common-Medium;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 24px;
}
.strategy .item .action {
  width: 100%;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #f17e00;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: #f17e00;
  line-height: 22px;
}
.strategy :global .ant-tabs-tab {
  font-size: 16px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.85);
  line-height: 24px;
}
.strategy :global .ant-tabs-tab-active {
  border-bottom: 3px solid #f17e00;
}
.strategy :global .ant-tabs-content-holder {
  margin-top: 24px;
  display: flex;
}
.detail {
  max-width: 1200px;
  margin: auto;
}
.detail .title {
  font-size: 22px;
  font-family: Common-Medium;
  font-weight: 500;
  color: #ffffff;
  line-height: 30px;
  margin-top: 16px;
  margin-bottom: 15px;
}
.detail .categroy {
  display: inline-block;
  height: 28px;
  padding: 0 10px;
  background: #f17e00;
  border-radius: 2px;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: #ffffff;
  line-height: 28px;
  text-align: center;
  margin-bottom: 24px;
}
.detail .detailContent {
  background: #1F212F;
  padding: 32px;
  border-radius: 2px;
}
.detail .detailContent .logo {
  max-height: 443px;
}
.detail .detailContent .introduction {
  margin-left: 16px;
  background: #2F3342;
  width: 427px;
  height: 443px;
  padding: 10px 32px 32px;
  border-radius: 2px;
}
.detail .detailContent .introduction p {
  color: rgba(255, 255, 255, 0.7);
}
.detail .detailContent .introduction .title {
  font-size: 24px;
  font-family: Common-Medium;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 30px;
  margin-bottom: 40px;
}
.detail .detailContent .introduction .green {
  font-size: 48px;
  font-family: Common-Medium;
  font-weight: 500;
  color: #09d687;
  line-height: 24px;
  margin-bottom: 20px;
}
.detail .detailContent .introduction .divider {
  width: 100%;
  height: 1px;
  margin: 10px 0 30px;
  background: rgba(255, 255, 255, 0.2);
}
.detail .detailContent .title {
  font-size: 22px;
  font-family: Common-Medium;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 30px;
  margin-bottom: 20px;
}
.detail .detailContent .subTitle {
  font-size: 16px;
  font-family: Common-Regular;
  font-weight: 400;
  color: #ffffff;
  line-height: 24px;
  margin-bottom: 10px;
}
.detail .detailContent .desc {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 24px;
  margin-bottom: 48px;
}
.detail .detailContent .investmentPhilosophy {
  margin-bottom: 48px;
}
.detail .detailContent .investmentPhilosophy .investmentPhilosophyContent {
  margin-bottom: 15px;
}
.detail .detailContent .investmentPhilosophy .investmentPhilosophyContent .top {
  color: #fff;
  font-family: Common-Medium;
  margin-bottom: 5px;
}
.detail .detailContent .investmentPhilosophy .investmentPhilosophyContent .bottom {
  color: rgba(255, 255, 255, 0.7);
  font-family: Common-Regular;
}
.detail .detailContent table.common {
  width: 100%;
  margin-bottom: 16px;
}
.detail .detailContent table.common tr td:first-child {
  width: 200px;
}
.detail .detailContent table.common tr td {
  padding: 4px 20px;
  border: 1px solid #1f212f;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 22px;
}
.detail .detailContent table.common tr:nth-child(odd) td {
  background: #2f3342;
}
.detail .detailContent table.common tr:nth-child(even) td {
  background: transparent;
}
.detail .detailContent .returnAndPerformance {
  margin-bottom: 48px;
}
.detail .detailContent .returnAndPerformance .area {
  height: 380px;
  background: #2f3342;
  border-radius: 2px;
  opacity: 0.69;
  padding: 5px 22px 22px;
  margin-bottom: 24px;
}
.detail .detailContent .returnAndPerformance .area .title {
  font-family: Common-Medium;
  color: #ffffff 85%;
  font-size: 16px;
}
.detail .detailContent .returnAndPerformance .smallTitle {
  font-family: Common-Medium;
  color: #ffffff 85%;
  font-size: 14px;
  margin-bottom: 17px;
}
.detail .detailContent .strategyDetails {
  margin-bottom: 48px;
}
.detail .detailContent .executiveCommittee {
  margin-bottom: 48px;
  display: grid;
  grid-template-columns: 170px auto;
  align-items: center;
}
.detail .detailContent .executiveCommittee .img {
  width: 150px;
  display: inline-block;
  margin-right: 20px;
}
.detail .detailContent .executiveCommittee .title {
  font-size: 26px;
  font-family: Common-Medium;
  font-weight: 500;
  color: #ffffff;
  line-height: 30px;
}
.detail .detailContent .executiveCommittee .name {
  font-size: 26px;
  font-family: Common-Medium;
  font-weight: 500;
  color: #ffffff;
}
.detail .detailContent .executiveCommittee .post {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  margin: 10px 0 15px;
}
.detail .detailContent .executiveCommittee .brief {
  margin-left: 20px;
  list-style: disc;
}
.detail .detailContent .executiveCommittee .brief li {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 22px;
}
.detail .detailContent .riskManagement {
  margin-bottom: 48px;
}
.detail .detailContent .riskManagement .risk {
  margin-bottom: 16px;
}
.detail .detailContent .riskManagement .risk p {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 24px;
}
.detail .detailContent .riskManagement .risk .riskTitle {
  font-size: 16px;
  font-family: Common-Medium;
  font-weight: 500;
  color: #f17e00;
  line-height: 24px;
  margin-bottom: 4px;
}
.detail .detailContent .riskManagement .risk .riskTitle:before {
  content: " ";
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #f17e00;
  border-radius: 50%;
  margin-right: 10px;
}
.detail .detailContent .questionlable {
  width: 32px;
  height: 32px;
  margin-right: 10px;
  display: inline-block;
  background: #f17e00;
  border-radius: 2px;
  margin-bottom: 20px;
  text-align: center;
  line-height: 32px;
}
.detail .detailContent .answerlable {
  width: 32px;
  line-height: 32px;
  height: 32px;
  margin-right: 10px;
  display: inline-block;
  background: #008eff;
  border-radius: 2px;
  text-align: center;
}
.detail .detailContent .qa {
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 26px;
  margin-top: 16px;
}
.detail .detailContent .qa img {
  width: 32px;
  height: 32px;
}
.detail .detailContent .item {
  display: grid;
  grid-template-columns: 40px auto;
}
.detail .detailContent .sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 20;
}
.detail .detailContentFaker {
  position: relative;
  line-height: 0;
}
.detail .detailContentFaker .subBtnWrap {
  width: 363px;
  height: 88px;
  position: absolute;
  right: 65px;
  top: 362px;
}
.detail .detailContentFaker .subscribeBtn {
  width: 363px;
  height: 40px;
}
.detail .detailContentFaker .redeemBtn {
  width: 363px;
  height: 40px;
  margin-top: 8px;
}
.subscribe .title {
  font-size: 22px;
  font-family: Common-Medium;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 30px;
  margin-bottom: 20px;
}
.subscribe .btn {
  padding: 9px 22px;
  height: 40px;
  font-family: Common-Regular;
  color: #f17e00;
  font-size: 14px;
  background: #1f212f;
  border: none;
}
.subscribe .btn.active {
  background: #f17e00;
  color: #fff;
}
.subscribe .btn.normal {
  border: 1px solid #424555;
  color: rgba(255, 255, 255, 0.7);
}
.subscribe .btn.disabled {
  color: rgba(255, 255, 255, 0.3);
  border-color: #434343;
  background: rgba(255, 255, 255, 0.08);
}
.subscribe .submitWrap {
  margin-top: 48px;
  text-align: center;
}
.subscribe .hint {
  font-family: Common-Regular;
  color: rgba(255, 255, 255, 0.7);
  line-height: 22px;
  margin-bottom: 24px;
}
.subscribe :global .ant-input-group-wrapper,
.subscribe :global .ant-input-affix-wrapper {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background: #1f212f;
  border-radius: 4px;
}
.subscribe .email-red {
  color: #f76260;
}
.confirmValue {
  font-size: 16px;
  font-family: Common-Medium;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 24px;
}
:global textarea.ant-input,
:global textarea.ant-input:hover,
:global textarea.ant-input:focus {
  border-radius: 2px;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
:global .ant-tooltip-inner {
  font-size: 12px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 20px;
  padding: 16px 20px;
}
:global .ant-anchor-link {
  margin-bottom: 16px;
}
:global .ant-anchor-ink::before {
  background-color: #f17e00;
}
:global .ant-anchor-link-title {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 22px;
}
