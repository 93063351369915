.footerDark {
  width: 100%;
  height: 52px;
  line-height: 52px;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  bottom: 0;
  /*
  .share-icon {
    max-width: 302px;
    float: right;
    .icon_logo {
      display: inline-block;
      width: 16px;
      height: 16px;
      background-repeat: no-repeat;
      background-size: contain;
      cursor: pointer;
    }
    .twitter:hover {
      background: transparent;
      background-image: url(@/assets/images/ic_twitter@2x.png);
    }
    .twitter {
      background-image: url(@/assets/images/ic_twitter@2x.png);
    }
    .facebook:hover {    
      background: transparent;
      background-image: url(@/assets/images/ic_facebook@2x.png);
    }
    .facebook {
      background-image: url(@/assets/images/ic_facebook.png);
    }

    img {
      width: 30px;
      cursor: pointer;
    }
  }
    */
}
.footerDark .content {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  max-width: 83%;
  margin: auto;
}
.footerDark .shareIcon {
  height: 30px;
}
.footerDark :gloabl svg:not(:root) {
  margin-top: 24px;
}
