.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1F212F;
  padding-left: 24px;
}
.headerLeft {
  width: 480px;
  color: #fff;
  line-height: 64px;
  display: flex;
  align-items: center;
}
.headerRight {
  width: 100%;
  display: block;
}
.search {
  width: 390px;
  height: 40px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
}
.logo {
  margin-right: 22px;
  cursor: pointer;
}
.defaultDrop :global .logout-dropdown .ant-dropdown-menu-item {
  min-width: 112px;
  height: 46px;
  display: flex;
  align-items: center;
  font-family: Common-Medium;
  color: #fff;
}
.subDrop :global .logout-dropdown {
  left: -113px !important;
}
.subDrop :global .logout-dropdown .ant-dropdown-menu-item {
  min-width: 112px;
  height: 46px;
  display: flex;
  align-items: center;
  font-family: Common-Medium;
  color: #fff;
}
