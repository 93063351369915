.status {
  display: inline-block;
  text-align: center;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.85);
  line-height: 22px;
}
.customerManagment {
  max-width: 1200px;
  margin: auto;
}
.customerManagment .top {
  max-width: 1200px !important;
  display: flex;
  justify-content: space-between;
}
.customerManagment .module {
  height: 250px;
  margin: auto;
  background: #1F212F;
  padding: 19px;
}
.customerManagment .module .agencyInfo {
  font-family: Common-Medium;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 16px;
}
.customerManagment .module .agencyDetail {
  margin-top: 20px;
  font-size: 14px;
  font-family: Common-Medium;
}
.customerManagment .module .agencyDetail .aum {
  font-size: 22px;
  font-family: Common-Bold;
}
.customerManagment .module .agencyDetail .copy {
  height: 32px;
  background: #2F3342;
  border-radius: 4px;
}
.customerManagment .module .agencyDetail .inviteCode {
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  font-family: Common-Bold;
}
.customerManagment .module .agencyDetail :global .ant-input[disabled] {
  padding-right: 60px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  height: 32px;
  line-height: 32px;
  background: #2F3342;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-family: Common-Medium;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.85);
  display: inline-block;
}
.customerManagment .module .bottomArea {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}
.customerManagment .module .title {
  font-family: Common-Regular;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 16px;
}
.customerManagment .module .search {
  display: flex;
  align-items: center;
  padding: 27px 0;
}
.customerManagment .module .search .input {
  width: 30%;
  height: 32px;
  margin: 0 35px;
}
.customerManagment .module .search .btn {
  width: 80px;
}
.customerManagment .moduleList {
  margin: auto;
  background: #1F212F;
  padding: 19px;
}
.customerManagment .moduleList .clientList .title {
  font-family: Common-Regular;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 16px;
}
.customerManagment .moduleList .clientList .loadMore {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  color: #f17e00;
  cursor: pointer;
}
.customerManagment .moduleList .clientList .loadMore:hover {
  color: #a45907;
}
.customerManagment .divider {
  width: 100%;
  height: 1px;
  background: linear-gradient(180deg, rgba(112, 115, 130, 0) 0%, #3d3f4b 100%);
}
.customerManagment .dividerLine {
  width: 100%;
  height: 1px;
  margin: 24px 0;
  background: linear-gradient(180deg, rgba(112, 115, 130, 0) 0%, #3d3f4b 100%);
}
.customerManagment .applicationInfo {
  margin: auto;
  background: #1F212F;
  padding: 24px;
}
.customerManagment .applicationInfo .title {
  font-size: 22px;
  color: rgba(255, 255, 255, 0.85);
}
.customerManagment .applicationInfo .marginBottom24 {
  margin-bottom: 24px;
}
.customerManagment .applicationInfo .accountInfo {
  display: flex;
}
.customerManagment .applicationInfo .accountInfo .key {
  width: 100px;
  margin-right: 100px;
}
.customerManagment .applicationInfo .authorizatioTh {
  height: 48px;
  background-color: rgba(255, 255, 255, 0.05);
  display: flex;
  align-items: center;
  margin: 24px 0;
}
.customerManagment .applicationInfo .authorizatioTh .authorizatioThFirst {
  width: 86px;
  margin: 0 105px 0 16px;
}
.customerManagment .applicationInfo .authorizatioTr {
  display: flex;
}
.customerManagment .applicationInfo .authorizatioTr .authorizatioTrFirst {
  width: 86px;
  margin: 0 105px 0 16px;
}
.customerManagment .applicationInfo .periodCheck {
  margin: 17px 0 9px;
}
.customerManagment .agreementContent {
  margin: auto;
  margin-top: 15px;
  background: #1F212F;
  padding: 24px;
}
.customerManagment .agreementContent .title {
  font-size: 22px;
  color: rgba(255, 255, 255, 0.85);
}
.customerManagment .agreementContent .agreementArea {
  padding: 24px;
  height: 600px;
  overflow: auto;
  background-color: #2f3342;
}
.customerManagment .btnContent .btnLeft {
  width: 130px;
  margin-right: 16px;
}
.customerManagment .btnContent .btnRight {
  width: 166px;
}
.greenBg {
  composes: status;
  background: #24be72;
  cursor: default;
}
.grayBg {
  composes: status;
  background: #535460;
  cursor: default;
}
.authorizationAgreement {
  padding: 30px 20px;
  font-family: Common-Regular;
  font-size: 14px;
}
.authorizationAgreement .title {
  font-size: 24px;
  font-family: Common-Bold;
  margin: 24px 0;
}
.authorizationAgreement .secondaryTitle {
  font-size: 22px;
  font-family: Common-Regular;
  margin-bottom: 20px;
  text-decoration: underline;
}
.authorizationAgreement .secondaryContent {
  margin-bottom: 16px;
}
.authorizationAgreement .secondaryContent header {
  margin-bottom: 14px;
  font-size: 20px;
}
.authorizationAgreement .secondaryContent p {
  margin-bottom: 14px;
}
