.exchange {
  left: 16px;
  right: 16px;
  width: calc(100vw - 32px);
  margin-left: calc((1200px - 100vw + 36px) / 2);
}
.exchange .girdKline {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: minmax(592px, auto) 340px 340px;
}
.exchange :global .ant-tabs-nav-operations {
  display: none !important;
}
.exchange :global .ant-radio-button-wrapper {
  border: none;
}
.exchange :global .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  width: 0;
}
.exchange :global .ant-input-disabled {
  background: transparent;
}
.exchange :global .ant-tabs-tab-btn {
  font-size: 16px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 24px;
}
.exchange :global .ant-tabs-tab {
  padding-bottom: 5px;
  border-bottom: 1px solid transparent;
}
.exchange :global .ant-tabs-tab-active {
  border-bottom-color: #F17E00;
}
.exchange :global .ant-radio-group {
  width: 102%;
  padding: 0;
}
.exchange :global .ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}
.exchange :global .ant-radio-button-wrapper {
  width: 49%;
  text-align: center;
}
.exchange :global .ant-radio-button-wrapper:first-child {
  border-left: none !important;
}
.exchange :global .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #F17E00;
  border-radius: 4px;
  color: #fff;
}
.exchange :global .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
}
.exchange :global .ant-form-item-control-input {
  height: 40px;
  border-radius: 4px;
}
.exchange :global .ant-input-group .ant-input {
  font-size: 16px;
  font-family: Common-Medium;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 24px;
  text-align: right;
}
.exchange :global .ant-input-group-addon {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  line-height: 22px;
}
.exchange :global .ant-input-group-addon:first-child {
  padding-right: 0;
}
.exchange :global .ant-input-group-addon:last-child {
  padding-left: 0;
}
.exchange :global .ant-form-item {
  margin-bottom: 21px;
}
.exchange :global .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
  background: #2F3342;
  border-radius: 4px;
  font-size: 16px;
  font-family: Common-Medium;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 24px;
  padding: 0 5px;
}
.exchange :global .ant-select-single .ant-select-selector .ant-select-selection-item,
.exchange :global .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 40px;
}
.trade {
  background: #1F212F;
  min-height: 550px;
  max-height: 85vh;
  padding: 24px 24px 20px;
}
.trade .tradeHeader {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
  column-gap: 10px;
}
.trade .tradeHeader :global .ant-select-single .ant-select-selector .ant-select-selection-search {
  left: 5px;
  top: 3px;
}
.trade .klineChartContainer {
  width: 100%;
  min-height: 390px;
  overflow: hidden;
  height: 80%;
  position: relative;
}
.trade .depthChartContainer {
  min-height: 390px;
  overflow: hidden;
  max-height: 80vh;
  height: 80%;
}
.trade .ticker {
  color: #f4f9ff;
}
.trade .price {
  font-size: 22px;
  font-family: Common-Medium;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 30px;
}
.trade .change,
.trade .switcher {
  font-size: 12px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.65);
  line-height: 20px;
}
.trade .switcher {
  cursor: pointer;
  margin-bottom: 16px;
  font-size: 14px;
}
.trade .switcher :global .ant-btn {
  min-width: 40px;
  height: 24px;
  font-size: 12px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.65);
  line-height: 20px;
  border: none;
  margin-right: 6px;
  border-radius: 4px;
  padding: 0;
}
.trade .switcher .activeInterval {
  padding: 0 5px;
  background: #2F3342;
  color: #F17E00 !important;
}
.trade .current {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.85);
}
.orderbook {
  min-height: 550px;
  max-height: 85vh;
  background: #1F212F;
  padding: 21px 15px;
  overflow-y: auto;
}
.action {
  color: #F17E00;
  cursor: pointer;
}
.refresh {
  border: 1px solid #f17e00 !important;
  color: #F17E00 !important;
}
.refresh:hover {
  color: rgba(255, 255, 255, 0.7) !important;
  transition: none;
}
.placeOrder {
  background: #1F212F;
  padding: 24px;
  min-height: 550px;
  max-height: 85vh;
  overflow-y: auto;
}
.placeOrder .btn {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 48px;
}
.placeOrder .avaliable {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  line-height: 22px;
}
.placeOrder .banlance {
  font-size: 14px;
  font-family: Common-Medium;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 22px;
}
.placeOrder .deposit {
  width: 54px;
  height: 24px;
  border-radius: 4px;
  line-height: 24px;
  padding: 0;
  font-size: 12px;
  font-family: Common-Regular;
  font-weight: 400;
  line-height: 22px;
  border: 1px solid #f17e00;
  color: #F17E00;
}
.placeOrder .deposit:hover {
  background: #F17E00;
  color: rgba(255, 255, 255, 0.7);
  transition: none;
}
.placeOrder :global .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.placeOrder :global .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.placeOrder :global .ant-form-item-control-input-content {
  background: transparent;
}
.placeOrder :global .ant-input-group-wrapper {
  background: #2F3342;
}
.placeOrder :global .ant-divider-horizontal {
  margin: 16px 0;
}
.placeOrder :global .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
  border: none;
  box-shadow: none;
}
.placeOrder :global .ant-form-item-explain.ant-form-item-explain-error {
  margin-top: -21px;
  margin-bottom: 5px;
}
.orderList {
  background: #1F212F;
  padding: 16px 24px 24px;
  margin-top: 16px;
  grid-column-start: 1;
  grid-column-end: 3;
}
.orderList :global .ant-table-thead > tr > th {
  padding: 6px;
  height: 57px;
  white-space: nowrap;
}
.orderList :global .ant-table-tbody > tr > td {
  padding: 10px 6px;
  height: 57px;
}
.orderList :global .ant-table-container table > thead > tr:first-child th:last-child.ant-table-cell-scrollbar {
  width: 0;
  padding: 0;
}
.orderList :global .ant-table-cell {
  /* white-space:nowrap; 防止IE等浏览器不支持'max-content'属性 导致内容换行 */
}
.orderList :global .ant-btn-primary {
  background: #F17E00;
  color: #fff;
  margin-right: 30px;
}
.orderList :global .ant-btn:hover {
  background: #F17E00;
  color: rgba(255, 255, 255, 0.85);
  transition: none;
}
.title {
  font-size: 16px;
  font-family: Common-Medium;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 24px;
  margin-bottom: 12px;
}
.orderbookHeader {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  line-height: 22px;
  display: flex;
  align-items: center;
}
.orderbookHeader .price,
.orderbookHeader .amount,
.orderbookHeader .total {
  width: 30%;
  flex: 1 1;
  overflow: hidden;
}
.orderbookHeader .amount {
  width: 35%;
  text-align: right;
  margin-right: 20px;
}
.orderbookHeader .total {
  text-align: right;
  width: 35%;
}
.orderbookContent {
  z-index: 1000;
  width: 100%;
  display: flex;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 4px;
}
.orderbookContent .price,
.orderbookContent .amount,
.orderbookContent .total {
  width: 30%;
  flex: 1 1;
  overflow: hidden;
}
.orderbookContent .amount {
  width: 35%;
  text-align: right;
  margin-right: 20px;
}
.orderbookContent .total {
  text-align: right;
  width: 35%;
}
.salesPertange {
  background: rgba(218, 72, 49, 0.05);
}
.buyPertange {
  background: rgba(45, 170, 64, 0.05);
}
.buyPertange,
.salesPertange {
  height: 22px;
  margin-bottom: 4px;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
}
.lastPrice {
  font-size: 22px;
  font-family: Common-Medium;
  font-weight: 500;
  color: #e0432b;
  line-height: 30px;
  margin: 16px 0;
}
.sell {
  color: #e0432b;
}
.buy {
  color: #2daa40;
}
.up {
  color: #2daa40ff !important;
}
.down {
  color: #e35e5e !important;
}
.remain {
  color: rgba(255, 255, 255, 0.85) !important;
}
.types {
  height: 32px;
  background: #2F3342;
  border-radius: 4px;
}
.active {
  width: 50%;
  height: 24px;
  background: #F17E00;
  border-radius: 4px;
}
.tradingGroup {
  display: flex;
  justify-content: space-between;
  flex: 0 1 auto;
  margin-bottom: 32px;
}
.tradingVolume {
  border-radius: 4px;
  border: 1px solid #2f3342;
  margin-right: 8px;
  color: rgba(255, 255, 255, 0.65);
  padding: 0 10px;
  flex-grow: 1;
}
@media (max-width: 1024px) {
  .exchange {
    margin-left: 0;
  }
}
.modal :global .ant-form-item-control-input .ant-input {
  background: #1F212F;
}
.modal :global .ant-form-item-control-input-content {
  background: transparent;
}
.modal :global .ant-modal-body {
  margin-bottom: 48px;
}
.modal :global .ant-modal-footer {
  display: flex;
  border-top: none;
  justify-content: flex-end;
  padding-bottom: 4px;
}
.modal :global .ant-modal-footer button:first-child {
  display: inline-block;
  margin-left: 16px;
  order: 1;
}
.modal :global .ant-modal-footer .ant-btn-primary {
  background: #F17E00;
}
.modal :global .ant-modal-footer .ant-btn {
  cursor: pointer;
  width: 108px;
  background: #4c5467;
  height: 40px;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: #ffffff;
  line-height: 22px;
  border-radius: 4px;
}
.modal :global .ant-modal-footer .ant-btn:hover,
.modal :global .ant-modal-footer .ant-btn:focus {
  border-color: transparent;
}
.modal :global .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  width: 108px;
}
.modal .ant-form-item-has-error :not(.ant-input-disabled).ant-input,
.modal .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper,
.modal .ant-form-item-has-error :not(.ant-input-disabled).ant-input:hover,
.modal .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:hover {
  background: #1F212F;
}
