.base {
  padding: 21px 32px 32px 33px;
  height: 474px;
  background: #1F212F;
  min-width: 350px;
}
.recommended {
  margin-top: 16px;
  background: #1F212F;
  border-radius: 2px;
  padding: 21px 24px 24px;
  margin-bottom: 16px;
}
.assetDisbution {
  position: relative;
  composes: base;
}
.assetDisbution .assetDisbutionChart {
  top: 20px;
  min-width: 350px;
  max-width: 100%;
  height: 300px;
  text-align: center;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.assetDisbution .assetDisbutionPercentage {
  position: relative;
  top: 270px;
  margin: auto;
}
.assetDisbution .assetDisbutionPercentage .type {
  font-family: Common-Regular;
  font-weight: 400;
  line-height: 22px;
  margin-right: 30px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  flex-shrink: 0;
  width: 40px;
}
.assetDisbution .assetDisbutionPercentage .item {
  margin-bottom: 12px;
  align-items: center;
}
.assetDisbution .assetDisbutionPercentage .circle {
  width: 10px;
  height: 10px;
  background-color: transparent;
  border-radius: 5px;
  border-style: solid;
  flex-shrink: 0;
  margin-right: 8px;
}
.assetDisbution .assetDisbutionPercentage .number {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 22px;
  margin-left: 30px;
}
.assetSituational {
  composes: base;
  height: 330px;
}
.assetSituational .top {
  text-align: right;
}
.assetSituational .active {
  background: #F17E00;
  color: rgba(255, 255, 255, 0.85);
  cursor: pointer !important;
}
.assetSituational .disabled {
  cursor: default !important;
  background: #F17E00;
  opacity: 0.3;
}
.assetSituational :global .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
  width: 64px !important;
  height: 28px;
}
.assetSituational :global .ant-tabs-nav-list {
  background: rgba(255, 255, 255, 0.05);
  width: 259px;
  width: 130px;
  height: 28px;
  border-radius: 15px;
  display: inline-block;
}
.assetSituational :global .ant-tabs-tab,
.assetSituational :global .ant-tabs-tab-btn {
  width: 64px;
  height: 28px;
  line-height: 28px;
  text-align: center;
}
.assetSituational :global .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 0;
}
.assetSituational :global .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  padding: 0;
  border: none;
  color: rgba(255, 255, 255, 0.85);
}
.assetSituational :global .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
  background: #F17E00;
  border-radius: 15px;
  width: 64px;
  height: 28px;
  text-align: center;
  z-index: -1;
}
.assetSituational :global .ant-btn:hover {
  background: transparent;
}
.assetSituational :global .ant-btn:focus {
  background: #F17E00;
}
.assetSituational :global .ant-picker.ant-picker-disabled {
  background-color: transparent !important;
  font-family: Common-Medium;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.85);
  border: none;
  padding: 4px 0;
}
.assetSituational :global .ant-picker.ant-picker-disabled .ant-picker-suffix {
  display: none;
}
.assetSituational :global .ant-picker.ant-picker-disabled .ant-picker-input > input[disabled] {
  text-align: center;
  color: rgba(255, 255, 255, 0.932);
}
.assetSituational :global .anticon-right,
.assetSituational :global .anticon-left {
  width: 20px;
  height: 20px;
  line-height: 1.5;
  border-radius: 2px;
  cursor: default;
}
.noDeposit .tips {
  margin-top: 19px;
  text-align: center;
  margin-bottom: 12px;
  font-size: 16px;
  font-family: Common-Medium;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 24px;
}
.noDeposit .actions {
  margin: auto auto 12px;
  text-align: center;
}
.noDeposit .actions .goToDepositFiatBtn:hover {
  color: #F17E00;
  border: 1px solid #F17E00;
}
.noDeposit .actions .goToDepositFiatBtn:focus {
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid #434343;
  background-color: transparent;
}
.noDeposit .subTips {
  text-align: center;
  font-size: 12px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.85);
  line-height: 20px;
  margin-bottom: 12px;
}
.apyModule {
  width: 100%;
  display: flex;
  flex: 0 1 auto;
  margin: 16px 0;
}
.apyModule .container {
  height: 140px;
  background: #1F212F;
  padding: 20px 24px 11px;
  flex-grow: 1;
  margin-right: 16px;
}
.apyModule .container img {
  width: 40px;
  height: 40px;
}
.apyModule .container:last-child {
  margin-right: 0;
}
.apyModule .container .title {
  font-size: 16px;
  font-family: Common-Medium;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 24px;
  margin-bottom: 4px;
}
.apyModule .container .apy {
  font-size: 28px;
  font-family: Common-Medium;
  font-weight: 500;
  color: #2cab3f;
  line-height: 36px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  margin-bottom: 10px;
}
.apyModule .container .date {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  line-height: 22px;
}
.pnl {
  composes: base;
  width: 100%;
  height: auto;
}
.myAsset {
  composes: base;
  width: 100%;
  height: auto;
  margin-top: 16px;
}
.myAsset .icon {
  margin-right: 16px;
  width: 48px;
  height: 48px;
}
.title {
  font-size: 16px;
  font-family: Common-Bold;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 24px;
}
.tooltip {
  width: 160px;
  height: 74px;
  background: #373e51;
  border-radius: 2px;
}
@media (max-width: 768px) {
  .assetDisbution,
  .ant-col .assetSituational {
    width: 10vw;
  }
  .pie {
    max-width: 50%;
    width: 100%;
    min-width: 250px;
  }
}
@media (min-width: 1441px) {
  .pie {
    max-width: 70%;
    width: 100%;
    min-width: 250px;
  }
}
/*markets Card*/
.marketsCard {
  background: #1F212F;
  padding: 20px;
  border-radius: 2px;
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: repeat(5, 20%);
  justify-content: space-between;
  align-items: center;
}
.marketsCard .item {
  border-right: 1px solid rgba(249, 250, 253, 0.1);
  border-radius: 2px;
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.marketsCard .item:first-child {
  padding-left: 0;
}
.marketsCard .item:last-child {
  padding-right: 0;
  border-right: none;
}
.marketsCard .item .label {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 22px;
  margin-bottom: 4px;
}
.marketsCard .item .value {
  font-size: 22px;
  font-family: Common-Medium;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 30px;
  margin-bottom: 8px;
}
.marketsCard .item .sublabel {
  font-size: 12px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 20px;
}
.marketsCard .item .down {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: #da4931;
  line-height: 22px;
}
.marketsCard .item .up {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: #09d687;
  line-height: 22px;
}
.notice {
  height: 48px;
  background: #1F212F;
  border-radius: 2px;
  margin-bottom: 16px;
  font-size: 12px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  padding: 0 10px;
  width: 100%;
  display: flex;
  align-items: center;
}
.notice .textLoop {
  display: grid;
  grid-template-columns: 40px 90px 440px 130px;
  grid-gap: 20px;
  cursor: pointer;
  align-items: center;
}
.notice .textLoop2 {
  display: grid;
  grid-template-columns: 130px 440px 130px;
  grid-gap: 20px;
  cursor: pointer;
  align-items: center;
}
.notice .title,
.notice .news,
.notice .brief {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.notice .new {
  position: relative;
  width: 40px;
  height: 20px;
  line-height: 20px;
  font-size: 10px;
  text-align: center;
  -webkit-transform: scale(0.8);
  display: inline-block;
  background: #F17E00;
  border-radius: 9px;
}
.notice :global .x1,
.notice :global .x4,
.notice :global .x5,
.notice :global .x6 {
  width: 100%;
}
.quickEentrance {
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
  width: 100%;
}
.quickEentrance .entrance {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 24%;
  height: 64px;
  background: #1F212F;
  border-radius: 2px;
  line-height: 64px;
  padding: 0 23px;
  font-size: 14px;
  font-family: Common-Medium;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 22px;
}
.quickEentrance .quickIcon {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}
.automaticInvesrmentPlan {
  max-width: 100%;
  margin-top: 16px;
  background: url("@/assets/images/banner@2x.png") no-repeat;
  height: 160px;
  background-size: cover;
}
.recommendedTitle {
  font-size: 16px;
  font-family: Common-Medium;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 24px;
  margin-bottom: 13px;
}
.recommendedSubtitle {
  font-size: 12px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 20px;
  padding-left: 10px;
}
.viewall {
  margin-top: 30px;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: #f17e00;
  line-height: 22px;
  text-align: center;
  cursor: pointer;
}
.viewall:hover {
  color: #a45907;
}
.wellStrategy {
  composes: recommended;
}
.wellStrategy .strategies {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wellStrategy .strategies .strategyListItem:nth-child(3n) {
  margin-right: 0px !important;
}
.wellStrategy .strategies .strategyListItem {
  width: 32%;
  margin-right: 22px;
}
.popularSavings {
  composes: recommended;
}
.savings {
  display: flex;
  justify-content: space-between;
}
.savings .savingItem {
  padding: 20px;
  border-radius: 4px;
  background: #2F3342;
  width: 100%;
  margin-right: 17px;
}
.savings .savingItem:last-child {
  margin-right: 0;
}
.savings .savingItem:hover {
  background: #353957;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.5);
}
.savings .savingItem .currency {
  display: inline-block;
  font-size: 16px;
  font-family: Common-Medium;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 20px;
}
.savings .savingItem .icon {
  width: 40px;
  height: 40px;
  margin-right: 8px;
}
.savings .savingItem .type {
  height: 18px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 9px;
  font-size: 12px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 20px;
  padding: 1px 8px;
  margin-right: 5px;
}
.savings .savingItem .apyLabel {
  font-size: 12px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 20px;
}
.savings .savingItem .apyValue {
  font-size: 14px;
  font-family: Common-Medium;
  font-weight: 500;
  color: #ffffff;
  line-height: 22px;
}
.savings .savingItem .action {
  width: 120px;
  height: 30px;
  background: #f17e00;
  border-radius: 18px;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: #ffffff;
  margin-top: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.automaticEntrance {
  padding-left: 100px;
  padding-top: 36px;
  height: 230px;
  background: url(@/assets/images/autoInvestment_home.png) no-repeat;
  background-size: 100%;
  margin: 16px 0;
}
.automaticEntrance .title {
  font-family: Common-Bold;
  color: #ffffff;
  font-size: 32px;
}
.automaticEntrance .container {
  margin: 10px 0 39px;
  width: 395px;
  font-family: Common-Regular;
  color: #ffffff 70%;
}
.automaticEntrance .automaticEntranceBtn {
  width: 140px;
  height: 40px;
  color: #ffffff;
  border-radius: 20px;
  font-family: Common-Medium;
}
