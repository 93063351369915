.savings {
  max-width: 1200px;
  margin: auto;
}
.savings :global .ant-btn {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: #F17E00;
  line-height: 22px;
  border-radius: 2px;
  border: 1px solid #F17E00;
}
.savings :global .ant-btn-primary {
  background: #F17E00;
  color: #fff;
  margin-right: 30px;
}
.savings :global .ant-btn:hover {
  background: #F17E00;
  color: rgba(255, 255, 255);
  transition: none;
}
.savings :global .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  background: transparent;
  pointer-events: none;
}
.savings :global .ant-table-thead > tr > th,
.savings :global .ant-table-sticky-holder {
  background-color: rgba(255, 255, 255, 0.05);
}
.savings .search {
  width: 390px;
  height: 40px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
}
.savings .searchContainer {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.savings .list {
  padding: 0 24px 24px;
  background: #26283a;
  min-height: 600px;
}
.savings .list .subscribe {
  width: 95px;
  height: 28px;
  border-radius: 2px;
  border: 1px solid #F17E00;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: #F17E00;
  line-height: 22px;
  padding-top: 0;
  padding-bottom: 0;
}
.savings .list .icon {
  margin-right: 8px;
}
.savings .balance {
  padding: 24px;
  background: #1F212F;
}
.savings .balance .title {
  font-size: 16px;
  font-family: Common-Medium;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 24px;
  margin-bottom: 28px;
}
.savings .balance .item {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  line-height: 22px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.savings .balance .item .totalSavings {
  font-size: 22px;
  font-family: Common-Medium;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 30px;
}
.savings .balance .item .value {
  font-size: 14px;
  font-family: Common-Medium;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 22px;
}
.savings .balance :global .ant-btn {
  width: 100%;
  height: 40px;
  border-radius: 4px;
}
.savings .automaticEntrance {
  padding: 25px;
  height: 154px;
  background: url(@/assets/images/autoInvestment.png) no-repeat;
  background-size: 100%;
  margin-bottom: 15px;
}
.savings .automaticEntrance p {
  margin-bottom: 10px;
  font-family: Common-Bold;
  color: #ffffff 85%;
  font-size: 24px;
}
.savings .automaticEntrance .automaticEntranceBtn {
  margin-top: 15px;
  width: 106px;
  border-radius: 20px;
  font-family: Common-Medium;
}
.savings .investmentPlanBtn {
  width: 160px;
  margin-right: 0px;
  color: #fff;
  font-family: Common-Medium;
}
.savings .tableWrap {
  display: flex;
  margin-top: 184px;
  position: relative;
}
.savings .tableWrap :global .ant-table-thead > tr > th {
  padding: 6px 16px;
  height: 57px;
}
.savings .tableWrap :global .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.savings .tableWrap :global .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
  width: 180px !important;
  height: 5px;
}
.savings .tableWrap :global .ant-tabs-top > .ant-tabs-nav,
.savings .tableWrap :global .ant-tabs-bottom > .ant-tabs-nav,
.savings .tableWrap :global .ant-tabs-top > div > .ant-tabs-nav,
.savings .tableWrap :global .ant-tabs-bottom > div > .ant-tabs-nav {
  margin-bottom: 0;
}
.savings .tableWrap :global .ant-tabs-top > .ant-tabs-nav::before,
.savings .tableWrap :global .ant-tabs-bottom > .ant-tabs-nav::before,
.savings .tableWrap :global .ant-tabs-top > div > .ant-tabs-nav::before,
.savings .tableWrap :global .ant-tabs-bottom > div > .ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: 1px solid #303030;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #303030;
  content: "";
}
.savings .tableWrap .investmentPlan {
  max-width: 1200px;
  background: #1f212f;
  border-radius: 2px;
  margin: auto;
  margin-top: 34px;
}
.savings .tableWrap .investmentPlan .investmentPlanArea {
  padding: 0 24px 32px;
  min-height: 600px;
}
.savings .tableWrap .investmentPlan .investmentPlanArea .actionBtn {
  margin-right: 16px;
  width: 95px;
  height: 28px;
  padding-top: 0;
  padding-bottom: 0;
}
.savings .tableWrap .investmentPlan .investmentPlanArea .loadMore {
  display: flex;
  justify-content: center;
  color: #f17e00;
  cursor: pointer;
}
.savings .tableWrap .investmentPlan .investmentPlanArea .loadMore:hover {
  color: #a45907;
}
.savings .tableWrap .investmentPlan .investmentPlanArea .table {
  min-height: 600px;
  margin-bottom: 30px;
  padding-top: 25px;
}
.savings .tableWrap .myInvestmentPlan {
  max-width: 1200px;
  background: #1f212f;
  border-radius: 2px;
  margin: auto;
  margin-top: 34px;
}
.savings .tableWrap .myInvestmentPlan .myInvestmentPlannArea {
  padding: 25px 24px 32px;
  min-height: 600px;
  font-family: Common-Medium;
  color: rgba(255, 255, 255, 0.85);
}
.savings .tableWrap .myInvestmentPlan .myInvestmentPlannArea .actionBtn {
  width: 95px;
  height: 28px;
  padding-top: 0;
  padding-bottom: 0;
}
.savings .tableWrap .myInvestmentPlan .myInvestmentPlannArea .loadMore {
  display: flex;
  justify-content: center;
  color: #f17e00;
  cursor: pointer;
}
.savings .tableWrap .myInvestmentPlan .myInvestmentPlannArea .loadMore:hover {
  color: #a45907;
}
.savings .tableWrap .myInvestmentPlan .myInvestmentPlannArea .table {
  min-height: 600px;
  margin-bottom: 30px;
}
.savings .tab {
  height: 64px;
  background-color: #1f212f;
}
.savings .tab :global .ant-tabs-tab {
  margin: 0;
  width: 180px;
}
.savings .tab :global .ant-tabs-tab-btn {
  padding-top: 5px;
  width: 100%;
  height: 64px;
  font-size: 18px;
  font-family: Common-Regular;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
}
.savings .tab :global .ant-tabs-tab-btn:hover {
  color: #fff;
}
.savings .tab :global .ant-tabs-tab-active {
  background-color: #26283a;
}
.savings .tab :global .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
}
.savings .savingTable {
  padding-top: 24px;
}
.savings .savingTable .icon {
  margin-right: 16px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
}
.savings .savingTable .lockUp {
  background: rgba(255, 255, 255, 0.1);
  width: 75px;
  height: 22px;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.savings .savingTable .actionBtn {
  width: 100px;
  height: 28px;
  padding: 0;
}
.savings .savingTable .countdown {
  width: 100px;
  height: 28px;
  background-color: #383949;
  border-radius: 2px;
}
.savings .savingTable .countdown .countdownStyle :global .ant-statistic-content {
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.savings .savingTable .countdown .countdownStyle :global .ant-statistic-content-value {
  color: #F17E00;
  font-size: 14px;
}
.savings .savingTable .table {
  min-height: 600px;
  margin-bottom: 30px;
}
.savings .savingTable .loadMore {
  display: flex;
  justify-content: center;
  color: #f17e00;
  cursor: pointer;
}
.savings .savingTable .loadMore:hover {
  color: #a45907;
}
.addInvestmentPlan {
  font-family: Common-Medium;
  color: rgba(255, 255, 255, 0.85);
}
.addInvestmentPlan :global .ant-btn {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: #F17E00;
  line-height: 22px;
  border-radius: 2px;
  border: 1px solid #F17E00;
}
.addInvestmentPlan :global .ant-btn-primary {
  background: #F17E00;
  color: #fff;
  margin-right: 30px;
}
.addInvestmentPlan :global .ant-btn:hover,
.addInvestmentPlan :global .ant-btn:active {
  background: #F17E00;
  color: rgba(255, 255, 255, 0.85);
  transition: none;
}
.addInvestmentPlan .container {
  margin-top: 19px;
  padding: 40px 80px;
  background-color: #1F212F;
}
.addInvestmentPlan .container .title {
  padding-bottom: 31px;
  font-size: 22px;
}
.addInvestmentPlan .container .smallTitle {
  padding-bottom: 24px;
  font-size: 16px;
  color: #fff;
}
.addInvestmentPlan .container .coinRateContent {
  padding: 0 20px;
}
.addInvestmentPlan .container .coinRateContent .rateItem {
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
}
.addInvestmentPlan .container .coinRateContent .rateItem .rateLeft {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.addInvestmentPlan .container .coinRateContent .rateItem .rateLeft .icon {
  margin-right: 16px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.addInvestmentPlan .container .coinRateContent .rateItem .rateLeft .fullName {
  color: #fff;
  font-weight: bold;
}
.addInvestmentPlan .container .coinRateContent .rateItem .rateLeft .coinName {
  color: rgba(255, 255, 255, 0.7);
}
.addInvestmentPlan .container .coinRateContent .rateItem .rateNum {
  height: 44px;
  line-height: 44px;
  color: #fff;
  font-size: 16px;
}
.addInvestmentPlan .container .balanceTitle {
  font-size: 16px;
  padding-bottom: 8px;
  font-family: Common-Regular;
  color: #fff;
}
.addInvestmentPlan .container .balanceValue {
  padding-bottom: 24px;
  font-size: 24px;
  color: #fff;
}
.addInvestmentPlan .container .cycleContainer {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
}
.addInvestmentPlan .container .cycleContainer p {
  font-family: Common-Regular;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 9px;
}
.addInvestmentPlan .container .cycleContainer .leftCycle {
  width: 45%;
}
.addInvestmentPlan .container .cycleContainer .leftCycle .limitTips {
  height: 10px;
}
.addInvestmentPlan .container .cycleContainer .leftCycle .limitTips p {
  color: #a61d24;
}
.addInvestmentPlan .container .cycleContainer .rightCycle {
  width: 45%;
}
.addInvestmentPlan .container .cycleContainer .rightCycle .selectArea {
  width: 100%;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7) !important;
  background-color: #2f3342;
}
.addInvestmentPlan .container .btns {
  margin-top: 48px;
}
.investmentHistory {
  font-family: Common-Medium;
  color: rgba(255, 255, 255, 0.85);
}
.investmentHistory :global .ant-btn {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: #F17E00;
  line-height: 22px;
  border-radius: 2px;
  border: 1px solid #F17E00;
}
.investmentHistory :global .ant-btn-primary {
  background: #F17E00;
  color: #fff;
  margin-right: 30px;
}
.investmentHistory :global .ant-btn:hover,
.investmentHistory :global .ant-btn:active {
  background: #F17E00;
  color: rgba(255, 255, 255, 0.85);
  transition: none;
}
.investmentHistory .container {
  margin-top: 25px;
  padding: 40px;
  background-color: #1F212F;
}
.investmentHistory .container .title {
  font-size: 22px;
  color: rgba(255, 255, 255, 0.85);
  margin-bottom: 30px;
}
.investmentHistory .container .actionBtn {
  width: 95px;
  height: 28px;
  padding-top: 0;
  padding-bottom: 0;
}
.investmentHistory .container .loadMore {
  display: flex;
  justify-content: center;
  color: #f17e00;
  cursor: pointer;
}
.investmentHistory .container .loadMore:hover {
  color: #a45907;
}
.investmentHistory .container .table {
  min-height: 600px;
  margin-bottom: 30px;
}
.automaticModal .amountSum {
  font-size: 24px;
  color: #fff;
  font-weight: bold;
}
.automaticModal .rateArea .topTitle {
  font-family: Common-Regular;
  counter-reset: rgba(255, 255, 255, 0.6);
  margin-bottom: 16px;
}
.automaticModal .rateArea .topContent {
  margin-bottom: 16px;
  font-family: Common-Medium;
  color: #fff;
}
.automaticModal .bottomArea p {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.automaticModal .bottomArea p .bottonInfoKey {
  color: rgba(255, 255, 255, 0.6);
}
.automaticModal .bottomArea p .bottonInfoValue {
  color: #fff;
}
.modal :global .ant-input-suffix:after {
  content: "";
  width: 1px;
  height: 14px;
  margin-left: 12px;
  background: #ffffff;
  opacity: 0.1;
}
.modal :global .ant-modal-content .ant-input-affix-wrapper {
  padding-right: 0;
}
.modal :global .ant-input-group-addon {
  padding-left: 0;
  padding-right: 0;
}
.modal :global .ant-modal-title {
  font-size: 16px;
  color: #fff;
}
.modal .label {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  line-height: 22px;
  margin-right: 10px;
}
.modal .number {
  font-size: 14px;
  font-family: Common-Medium;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 22px;
}
.modal .tradingGroup {
  display: flex;
  justify-content: space-between;
  flex: 0 1 auto;
  margin-bottom: 26px;
  margin-top: 10px;
}
.modal .tradingVolume {
  height: 24px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-right: 8px;
  padding: 0 10px;
  flex-grow: 1;
}
.modal .item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.modal .termsAggrement {
  position: absolute;
  bottom: 40px;
  display: flex;
  left: 0;
  width: 100%;
  justify-content: center;
}
.modal .aggrement {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: #f17e00;
  line-height: 22px;
  text-decoration: underline;
}
.modal .successfully {
  font-size: 18px;
  font-family: Common-Medium;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 21px;
  margin-top: 18px;
  margin-bottom: 86px;
}
.savingsList .actionBtn :global {
  width: 95px;
  height: 28px;
  padding-top: 0;
  padding-bottom: 0;
  color: #F17E00;
  border: 1px solid #F17E00;
}
.savingsList .actionBtn:focus {
  color: #F17E00;
  border: 1px solid #F17E00;
}
.savingsList :global .ant-btn {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: #F17E00;
  line-height: 22px;
  border-radius: 2px;
  border: 1px solid #F17E00;
}
.savingsList :global .ant-btn-primary {
  background: #F17E00;
  color: #fff;
  margin-right: 30px;
}
.savingsList :global .ant-btn:hover,
.savingsList :global .ant-btn:active {
  background: #F17E00;
  color: rgba(255, 255, 255, 0.85);
  transition: none;
}
.savingsList :global .ant-switch {
  background-color: #5a5b6b;
}
.savingsList :global .ant-switch-checked {
  background-color: #f17e00;
}
.savingsList :global .ant-switch-handle::before {
  background-color: #26283a;
}
.savingsList :global .ant-table-content {
  min-height: 235px;
}
.savingsList .icon {
  margin-right: 16px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
}
.savingsList .category {
  padding: 3px 16px;
  height: 28px;
  text-align: center;
  display: inline-block;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 14px;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.85);
  line-height: 22px;
}
.autoModal :global .ant-modal-footer {
  display: flex;
  justify-content: flex-end;
  border-top: none;
  padding: 0;
}
.autoModal :global .ant-modal-footer button:first-child {
  display: inline-block !important;
  margin-left: 16px;
  order: 1;
}
.autoModal :global .ant-modal-footer .ant-btn {
  cursor: pointer;
  width: 108px;
  background: #2f3342;
  height: 40px;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 22px;
  border: 1px solid #424555;
  border-radius: 4px;
  margin-top: 20px;
}
.autoModal :global .ant-modal-footer .ant-btn-primary {
  background: #F17E00 !important;
  color: #fff;
}
.autoModal :global .ant-modal-footer .ant-btn-primary:hover {
  background: #ff6500 !important;
}
.autoModal :global .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  width: 108px;
}
.automaticModal .topTitle {
  font-family: Common-Regular;
  counter-reset: rgba(255, 255, 255, 0.6);
  margin-bottom: 16px;
}
.automaticModal .topContent {
  margin-bottom: 16px;
  font-family: Common-Medium;
  color: #fff;
}
.automaticModal .middleArea p {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.automaticModal .middleArea p .middleKey {
  font-family: Common-Regular;
  color: rgba(255, 255, 255, 0.6);
}
.automaticModal .middleArea p .middleValue {
  color: #fff;
  font-family: Common-Medium;
}
.automaticModal .checkbox .checkboxText {
  margin: 0 5px 0 16px;
  color: #fff;
}
.subFixedYield {
  font-family: Common-Medium;
  color: rgba(255, 255, 255, 0.85);
}
.subFixedYield :global .ant-btn {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: #F17E00;
  line-height: 22px;
  border-radius: 2px;
  border: 1px solid #F17E00;
}
.subFixedYield :global .ant-btn-primary {
  background: #F17E00;
  color: #fff;
  margin-right: 30px;
}
.subFixedYield :global .ant-btn:hover,
.subFixedYield :global .ant-btn:active {
  background: #F17E00;
  color: rgba(255, 255, 255, 0.85);
  transition: none;
}
.subFixedYield .container {
  margin-top: 19px;
  min-height: 900px;
}
.subFixedYield .container .area {
  padding: 41px 103px;
  background-color: #1F212F;
}
.subFixedYield .container .area .title {
  font-size: 22px;
  color: #fff;
  margin-bottom: 36px;
}
.subFixedYield .container .area .title .icon {
  margin-right: 16px;
  width: 40px;
  height: 40px;
}
.subFixedYield .container .area .content {
  display: flex;
  justify-content: space-between;
}
.subFixedYield .container .area .content .secTitle {
  font-size: 16px;
  color: #fff;
  margin-bottom: 20px;
}
.subFixedYield .container .area .content .availableArea {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.subFixedYield .container .area .content .availableArea .availableKye {
  color: rgba(255, 255, 255, 0.6);
}
.subFixedYield .container .area .content .availableArea .availablevalue {
  color: #fff;
}
.subFixedYield .container .area .content .leftContent {
  width: 42%;
}
.subFixedYield .container .area .content .leftContent .thirdTitle {
  font-size: 16px;
  color: #fff;
  margin-bottom: 8px;
}
.subFixedYield .container .area .content .leftContent .amount {
  font-size: 24px;
  color: #fff;
  font-family: Common-Bold;
  margin-bottom: 24px;
}
.subFixedYield .container .area .content .leftContent .inputContent {
  display: flex;
  position: relative;
  background-color: #2f3342;
  padding-right: 19px;
  border-radius: 2px;
}
.subFixedYield .container .area .content .leftContent .inputContent .amountInput {
  width: 100%;
  background-color: #2f3342;
}
.subFixedYield .container .area .content .leftContent .inputContent .amountInput input::-webkit-outer-spin-button,
.subFixedYield .container .area .content .leftContent .inputContent .amountInput input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.subFixedYield .container .area .content .leftContent .inputContent .maxBtn {
  position: absolute;
  right: 19px;
  height: 40px;
  line-height: 40px;
  color: #F17E00;
  cursor: pointer;
}
.subFixedYield .container .area .content .leftContent .errorTips {
  color: #a61d24;
  margin-bottom: 14px;
}
.subFixedYield .container .area .content .leftContent .checkbox .checkboxText {
  margin: 0 9px 0 16px;
  color: #fff;
}
.subFixedYield .container .area .content .leftContent .btnArea {
  margin-top: 56px;
  display: flex;
}
.subFixedYield .container .area .content .rightContent {
  width: 50%;
}
.subFixedYield .container .area .content .rightContent .timeArea {
  font-size: 14px;
  display: flex;
  margin-bottom: 20px;
}
.subFixedYield .container .area .content .rightContent .timeArea .first {
  width: 29%;
}
.subFixedYield .container .area .content .rightContent .timeArea .first .firstTitle {
  margin-bottom: 24px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  background: #07c160;
}
.subFixedYield .container .area .content .rightContent .timeArea .first .firstTitle .firstContent {
  text-align: start;
}
.subFixedYield .container .area .content .rightContent .timeArea .second {
  width: 39%;
}
.subFixedYield .container .area .content .rightContent .timeArea .second .secondTitle {
  margin-bottom: 24px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  background: #fa5151;
}
.subFixedYield .container .area .content .rightContent .timeArea .second .secondContent {
  text-align: center;
}
.subFixedYield .container .area .content .rightContent .timeArea .third {
  width: 32%;
}
.subFixedYield .container .area .content .rightContent .timeArea .third .thirdTitle {
  margin-bottom: 24px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  background: #f17e00;
}
.subFixedYield .container .area .content .rightContent .timeArea .third .thirdContent {
  text-align: end;
}
.subFixedYield .container .area .content .rightContent .apySteps {
  margin: 22px 0;
}
.subFixedYield .container .area .content .rightContent .apySteps .apyStepTableFlex {
  margin-top: 14px;
  font-size: 12px;
  display: flex;
}
.subFixedYield .container .area .content .rightContent .apySteps .apyStepTableFlex .apyStepTableFlexTitle :global .ant-row {
  width: 130px;
  height: 32px;
  align-items: center;
  padding-left: 10px;
  border-right: 1px solid #1f212f;
}
.subFixedYield .container .area .content .rightContent .apySteps .apyStepTableFlex .apyStepTableFlexTitle :global .ant-row:nth-child(odd) {
  background: #2f3342;
}
.subFixedYield .container .area .content .rightContent .apySteps .apyStepTableFlex .apyStepTableFlexContent {
  width: calc(100% - 130px);
  color: #fff;
  overflow-x: scroll;
  /* 设置滚动条的样式 */
}
.subFixedYield .container .area .content .rightContent .apySteps .apyStepTableFlex .apyStepTableFlexContent::-webkit-scrollbar {
  height: 3px;
}
.subFixedYield .container .area .content .rightContent .apySteps .apyStepTableFlex .apyStepTableFlexContent :global .ant-row {
  flex-wrap: nowrap;
  height: 32px;
  align-items: center;
}
.subFixedYield .container .area .content .rightContent .apySteps .apyStepTableFlex .apyStepTableFlexContent :global .ant-row > .ant-col {
  flex: 1 1;
  flex-basis: 105px;
  flex-shrink: 0;
  text-align: center;
  line-height: 32px;
}
.subFixedYield .container .area .content .rightContent .apySteps .apyStepTableFlex .apyStepTableFlexContent :global .ant-row > .ant-col:not(:first-child) {
  border-left: 1px solid #1f212f;
}
.subFixedYield .container .area .content .rightContent .apySteps .apyStepTableFlex .apyStepTableFlexContent :global .ant-row:nth-child(odd) > .ant-col {
  background: #2f3342;
}
