.login {
  width: 100%;
  min-height: 670px;
  height: 100%;
  background-image: url("@/assets/images/image_bg@2x.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.login label {
  position: relative;
  padding-left: 11px;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
}
.login :global .ant-form {
  display: table;
  width: 100%;
}
.login :global .ant-form-vertical .ant-form-item {
  margin-bottom: 32px;
  position: relative;
}
.login :global .ant-form-item-control {
  height: 60px;
  background: #2F3342;
  position: relative;
}
.login :global .ant-form-item-control-input {
  min-height: 60px;
}
.login :global .ant-form-item {
  line-height: 60px;
}
.login :global .ant-input {
  position: absolute;
  height: 60px;
  left: 0;
  top: 0;
  border-radius: 2px;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.9);
  padding-top: 35px;
  padding-bottom: 13px;
  background: transparent;
  /* background: @ant-input; */
}
.login :global .ant-input:hover {
  opacity: 0.35;
}
.login :global .ant-input[disabled] {
  color: rgba(255, 255, 255, 0.9);
}
.login :global .ant-form-item-explain-error {
  font-size: 12px;
  padding-top: 3px;
}
.login .main {
  height: 98vh;
  min-height: 670px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.login .main .mainContainer {
  max-width: 960px;
  width: 100%;
  height: 670px;
  margin: auto;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 50% 50%;
}
.login .main .mainContainer .leftBrief {
  height: 670px;
  background: #ffffff;
  border-radius: 4px 0px 0px 4px;
  padding: 48px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.login .main .mainContainer .leftBrief img.logo {
  width: 158px;
  height: 73px;
  margin-bottom: 24px;
}
.login .main .mainContainer .leftBrief .title {
  font-size: 18px;
  font-family: Common-Regular;
  font-weight: 400;
  color: #666666;
  line-height: 21px;
  margin-bottom: 41px;
}
.login .main .mainContainer .leftBrief .content .item {
  font-size: 14px;
  font-family: Common-light;
  font-weight: 300;
  color: #333333;
  line-height: 22px;
  display: grid;
  grid-template-columns: 56px auto;
  grid-gap: 12px;
  margin-bottom: 47px;
  align-items: center;
}
.login .main .mainContainer .leftBriefBlack {
  height: 670px;
  background: #000;
  border-radius: 4px 0px 0px 4px;
  padding: 48px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.login .main .mainContainer .leftBriefBlack img.logo {
  width: 158px;
  height: 73px;
  margin-bottom: 24px;
}
.login .main .mainContainer .leftBriefBlack .title {
  font-size: 18px;
  font-family: Common-Regular;
  font-weight: 400;
  color: #fff;
  line-height: 21px;
  margin-bottom: 41px;
}
.login .main .mainContainer .leftBriefBlue {
  height: 670px;
  background: #314196;
  border-radius: 4px 0px 0px 4px;
  padding: 48px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.login .main .mainContainer .leftBriefBlue img.logo {
  width: 158px;
  height: 73px;
  margin-bottom: 24px;
}
.login .main .mainContainer .leftBriefBlue .title {
  font-size: 18px;
  font-family: Common-Regular;
  font-weight: 400;
  color: #fff;
  line-height: 21px;
  margin-bottom: 41px;
}
.login .main .mainContainer .form {
  position: absolute;
  right: 9.7%;
  /*136px*/
  position: relative;
  right: 0;
  border-radius: 0 4px 4px 0;
  background: #23262f;
  height: 670px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login .main .mainContainer .form .container {
  width: 80%;
}
.login .main .mainContainer .form .container .titleArea {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.login .main .mainContainer .form .container .titleArea .goBack {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
}
.login .main .mainContainer .form .container .titleArea .goBack:hover {
  color: #F17E00;
}
.login .main .mainContainer .form .container .link {
  color: #F17E00;
  padding: 0 5px;
}
.login .main .mainContainer .form .container .active {
  top: -15px !important;
  z-index: 1 !important;
  color: rgba(255, 255, 255, 0.35) !important;
}
.login .main .mainContainer .form .container .inputHover {
  opacity: 1;
}
.login .main .mainContainer .formSAS {
  position: absolute;
  right: 9.7%;
  /*136px*/
  position: relative;
  right: 0;
  border-radius: 0 4px 4px 0;
  color: #000;
  background: #fff;
  height: 670px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login .main .mainContainer .formSAS .container {
  width: 80%;
  background: #fff;
}
.login .main .mainContainer .formSAS .container .title {
  color: #000;
}
.login .main .mainContainer .formSAS .container label {
  position: relative;
  padding-left: 11px;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(102, 102, 102, 0.7) !important;
}
.login .main .mainContainer .formSAS .container .active {
  top: -15px !important;
  z-index: 1 !important;
  color: #666 !important;
}
.login .main .mainContainer .formSAS .container .inputHover {
  opacity: 1;
}
.login .main .mainContainer .formSAS .container :global .ant-form {
  display: table;
  width: 100%;
}
.login .main .mainContainer .formSAS .container :global .ant-btn-primary {
  border: none !important;
}
.login .main .mainContainer .formSAS .container :global .ant-form-vertical .ant-form-item {
  margin-bottom: 32px;
  position: relative;
}
.login .main .mainContainer .formSAS .container :global .ant-btn-primary[disabled] {
  background-color: #ebc799;
}
.login .main .mainContainer .formSAS .container :global .ant-btn-primary[disabled]:hover {
  background-color: #ebc799 !important;
}
.login .main .mainContainer .formSAS .container :global .ant-form-item-control {
  height: 60px;
  background: #f2f3f5;
  position: relative;
}
.login .main .mainContainer .formSAS .container :global .ant-form-item-control-input {
  min-height: 60px;
}
.login .main .mainContainer .formSAS .container :global .ant-form-item-control-input-content {
  background: #f2f3f5;
}
.login .main .mainContainer .formSAS .container :global .ant-form-item {
  line-height: 60px;
}
.login .main .mainContainer .formSAS .container :global .ant-input {
  position: absolute;
  height: 60px;
  left: 0;
  top: 0;
  border-radius: 2px;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: #000000 !important;
  padding-top: 35px;
  padding-bottom: 13px;
  background: transparent;
  -webkit-text-fill-color: #000;
  caret-color: #000;
}
.login .main .mainContainer .formSAS .container :global .ant-form-item-explain-error {
  font-size: 12px;
  padding-top: 3px;
}
.login .header {
  height: 64px;
  background: #1F212F;
  display: flex;
  align-items: center;
  padding: 0 24px 0;
}
.login .mainEmail {
  position: absolute;
  height: 60px;
  left: 12px;
  top: 10px;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.9);
  background: transparent;
}
.login .register .tips {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 22px;
  margin-bottom: 32px;
}
.login .register .titleArea {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.login .register .titleArea .goBack {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 40px;
  margin-bottom: 16px;
  cursor: pointer;
}
.login .register .titleArea .goBack:hover {
  color: #F17E00;
}
.login .title {
  font-family: Common-Bold;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 24px;
  font-size: 22px;
  font-weight: 500;
  line-height: 36px;
}
.login .subtitle {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 22px;
  margin-bottom: 24px;
}
.login .submit {
  width: 100%;
  height: 56px;
  border-radius: 2px;
  font-size: 18px;
  font-family: Common-Medium;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.9);
  line-height: 24px;
}
.login .sendEmail {
  width: 100%;
  background-color: #353945;
  height: 60px;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: #F17E00;
  line-height: 22px;
  border: none;
}
.login .commonTips {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 22px;
}
.login .commonTipsForget {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 22px;
}
.login .commonTipsForget:hover {
  color: #F17E00;
}
.login .toRegister,
.login .toLogin {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: #F17E00;
  line-height: 22px;
  cursor: pointer;
}
.login .toRegister:hover,
.login .toLogin:hover {
  color: #F17E00;
}
.login .toLogin {
  color: rgba(255, 255, 255, 0.7);
}
.login .toLogin:hover {
  color: #F17E00;
}
.login .tips {
  color: #aaa;
}
.login .eamilGroup {
  display: grid;
  grid-template-columns: auto 116px;
  grid-gap: 16px;
}
.registerStep2Title {
  composes: title;
  margin-bottom: 0 !important;
}
.registerStep2 :global .ant-form-item {
  margin-bottom: 24px;
}
.registerStep2 :global .ant-form-item-explain-error {
  margin-top: -2px;
  font-size: 12px;
}
.registerStep2 .policy {
  display: flex;
  font-family: Common-Regular;
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
  margin-top: 16px;
}
.privatePolicy {
  padding: 60px 100px;
  background: #fff;
}
.privatePolicy .title {
  font-family: Common-Bold;
  color: #000;
  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
}
.privatePolicy .content {
  font-family: Common-Regular;
  color: #000;
  font-size: 14px;
}
.privatePolicy .content .conTitle1 {
  font-family: Common-Bold;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 700;
  text-decoration: underline;
}
.privatePolicy .content .conTitle2 {
  text-indent: 22px;
}
.privatePolicy .content .conContent {
  text-indent: 42px;
}
