.profile .profileTop {
  max-width: 1200px !important;
  display: flex;
  justify-content: space-between;
}
.profile .cancatUs {
  background: #F17E00;
  width: 134px;
  height: 40px;
  line-height: 40px;
  font-family: Common-Regular;
  color: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
  padding: 0;
  box-shadow: none;
  border: none;
}
.profile .module {
  margin: auto;
  background: #1F212F;
  padding: 24px;
  width: 100%;
  width: 1200px;
  /* 防止屏幕过宽显示不好看*/
  margin-bottom: 16px;
}
.profile .module .action {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: #F17E00;
  line-height: 22px;
  border: none;
  text-align: left;
  padding-left: 0;
}
.profile .module .using {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: #09d687;
  line-height: 22px;
}
.profile .module .kycInfo {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.85);
  line-height: 22px;
  overflow: hidden;
}
.profile .module .notCertified {
  padding-top: 44px;
  padding-bottom: 47px;
  cursor: pointer;
}
.profile .module .notCertified .content {
  font-size: 22px;
  font-family: Common-Regular;
  font-weight: 400;
  color: #ffffff;
  line-height: 22px;
  margin-bottom: 27px;
}
.profile .module .notCertified .individualsBtn {
  border: 1px solid #f17e00 !important;
  color: #F17E00 !important;
}
.profile .module .notCertified .individualsBtn:hover {
  color: #ffffff !important;
  background-color: #ff6500 !important;
  transition: none;
}
.profile .module .notCertified :global .ant-btn {
  width: 108px;
  padding: 0 4px;
  margin-left: 40px;
  height: 28px;
  border-radius: 2px;
  border: 1px solid #F17E00;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: #F17E00;
  line-height: 22px;
}
.profile .module .notCertified :global .ant-btn-primary {
  color: #fff;
}
.profile .module .subModule {
  color: rgba(104, 95, 85);
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
}
.profile .module .title {
  font-size: 22px;
  font-family: Common-Medium;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 30px;
  margin-bottom: 24px;
}
.profile .module .titleActionLink {
  font-size: 14px;
  color: #f17e00;
  cursor: pointer;
  margin-bottom: 24px;
}
.profile .module .subTitle {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 22px;
}
.profile .module .divider {
  width: 100%;
  height: 1px;
  background: linear-gradient(180deg, rgba(112, 115, 130, 0) 0%, #3d3f4b 100%);
}
.profile .module .section {
  position: relative;
}
.profile .module .section img {
  width: 44px;
  height: 44px;
}
.profile .module .section :global .ant-row {
  width: 100%;
  margin-top: 26px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}
.profile .module .section :global .ant-input[disabled] {
  cursor: pointer;
  font-size: 22px;
  font-family: Common-Medium;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.85);
  line-height: 40px;
  display: inline-block;
}
.profile .module .section .label {
  font-size: 16px;
  font-family: Common-Regular;
  font-weight: 400;
  color: #ffffff;
  line-height: 24px;
}
.profile .module .section .value {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 22px;
}
.profile .module .subAccountContent .titleContent {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profile .module .subAccountContent .titleContent .titleContentLeft {
  display: flex;
  align-items: center;
}
.profile .module .subAccountContent .titleContent .titleContentLeft .titleName {
  font-size: 22px;
  font-family: Common-Medium;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
}
.profile .module .subAccountContent .titleContent .titleContentLeft .frizenTag {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 24px;
  background: #3d90dd;
  border-radius: 12px;
  margin-left: 24px;
}
.profile .module .subAccountContent .titleContent .titleContentLeft .ReviewTag {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 24px;
  background: #F17E00;
  border-radius: 12px;
  margin-left: 24px;
}
.profile .module .subAccountContent .titleContent .addBtn {
  padding: 7px 16px;
  font-family: Common-Medium;
  color: rgba(255, 255, 255, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile .module .subAccountContent .accountContainer {
  padding: 24px 0 0 0;
}
.profile .module .subAccountContent .accountContainer .addAccountText {
  color: rgba(255, 255, 255, 0.85);
  text-align: center;
}
.profile .module .subAccountContent .accountContainer .subAccountInfo .subAccountItem {
  margin-bottom: 24px;
  color: rgba(255, 255, 255, 0.9);
}
.profile .module .subAccountContent .accountContainer .subAccountInfo .subAccountItem .subAccountKey {
  display: inline-block;
  width: 120px;
  margin-right: 90px;
}
.profile .module .subAccountContent .accountContainer .subAccountInfo .subAccountItem .subAccountAgreement {
  color: #F17E00;
  cursor: pointer;
}
.profile .module .serviceAccountContainer {
  padding-top: 24px;
}
.profile .module .serviceAccountContainer .accountText {
  text-align: center;
}
.profile .button {
  width: 66px;
  height: 36px;
  background: #f17e00;
  border-radius: 18px;
  color: #fff;
  font-size: 16px;
  font-family: Common-Medium;
  font-weight: 500;
  color: #ffffff;
  line-height: 36px;
  text-align: center;
}
.googeRemove :global .ant-modal-footer {
  display: block;
  justify-content: start;
}
.googeRemove :global .ant-modal-footer button:first-child {
  display: none;
}
.googeRemove :global .ant-modal-content {
  min-height: 320px;
}
.googeRemove .tips {
  position: absolute;
  bottom: 20px;
  left: 32px;
  right: 32px;
  width: calc(100% - 32px - 32px);
}
.modal {
  /*
    .ant-btn{
        cursor: pointer;
        width: 100%;
        background: #4C5467;
        height: 40px;
        font-size: 14px;
        font-family:@CommonRegular;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 22px;
        border-radius: 4px;
        margin-bottom: 24px;
    }
    */
}
.modal :global .ant-form-item-control-input .ant-input {
  background: #1F212F;
}
.modal :global .ant-form-item-control-input-content {
  background: transparent;
}
.modal :global .ant-modal-header .ant-modal-title {
  color: #fff;
}
.modal :global .ant-modal-body {
  margin-bottom: 48px;
}
.modal :global .ant-modal-footer {
  display: flex;
  border-top: none;
  justify-content: flex-end;
  padding-bottom: 4px;
}
.modal :global .ant-modal-footer button:first-child {
  display: inline-block;
  margin-left: 16px;
  order: 1;
}
.modal :global .ant-modal-footer .ant-btn-primary {
  background: #F17E00;
}
.modal :global .ant-modal-footer .ant-btn {
  cursor: pointer;
  width: 108px;
  background: #4c5467;
  height: 40px;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: #ffffff;
  line-height: 22px;
  border-radius: 4px;
}
.modal :global .ant-modal-footer .ant-btn:hover,
.modal :global .ant-modal-footer .ant-btn:focus {
  border-color: transparent;
}
.modal :global .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  width: 108px;
}
.modal .sendEmail {
  background: #1F212F;
  color: #F17E00;
  margin-left: 12px;
  height: 40px;
}
.modal .getKey {
  width: 100%;
  height: 40px;
  background: #4c5467;
  border-radius: 4px;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: #ffffff;
  line-height: 22px;
  margin-bottom: 24px;
}
.modal .qrcode {
  width: 100%;
  margin-bottom: 24px;
  display: grid;
  grid-template-columns: 116px auto;
}
.modal .googlekey {
  background: #3a3f52;
  text-align: center;
  width: 283px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal .step {
  font-size: 14px;
  font-family: Common-Medium;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 22px;
  margin-bottom: 4px;
}
.modal .desc {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 22px;
  margin-bottom: 24px;
}
.modal .addSubContent .bottomArea {
  margin-top: 30px;
}
.modal .addSubContent .bottomArea .Agreements {
  margin-left: 22px;
  color: #f17e00;
  cursor: pointer;
}
.modal .addSubContent .emailStyle {
  margin-bottom: 30px;
  color: #d35952;
}
.modal .ant-form-item-has-error :not(.ant-input-disabled).ant-input,
.modal .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper,
.modal .ant-form-item-has-error :not(.ant-input-disabled).ant-input:hover,
.modal .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:hover {
  background: #1F212F;
}
.emphasize {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: #f76260;
  line-height: 22px;
}
.customerList {
  width: 100%;
  background: #1f212f;
  padding: 24px;
}
.customerList .title {
  font-size: 18px;
  font-family: Common-Medium;
  font-weight: 500;
  color: #ffffff;
  line-height: 26px;
  margin-bottom: 26px;
  text-align: left;
}
.fundAssetDisbution {
  background: #242634 !important;
  border-radius: 4px;
  border: 1px solid #2f3342;
  height: 240px !important;
  padding: 24px;
}
.fundAssetDisbution .fundAssetDisbutionChart {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-top: -40px;
  height: 180px;
  width: 180px;
  overflow: hidden;
}
.fundAssetDisbution .fundAssetDisbutioPercentage {
  position: relative;
}
.fundAssetDisbution .fundAssetDisbutioPercentage .type {
  font-family: Common-Regular;
  font-weight: 400;
  line-height: 22px;
  margin-right: 30px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  flex-shrink: 0;
  width: 40px;
  display: block;
}
.fundAssetDisbution .fundAssetDisbutioPercentage .item {
  margin-bottom: 12px;
  align-items: center;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.85);
  line-height: 22px;
}
.fundAssetDisbution .fundAssetDisbutioPercentage .circle {
  width: 10px;
  height: 10px;
  background-color: transparent;
  border-radius: 5px;
  border-style: solid;
  flex-shrink: 0;
  margin-right: 8px;
}
.fundAssetDisbution .fundAssetDisbutioPercentage .number {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 22px;
}
.fundAssetDisbution :global .ant-progress-outer {
  width: 100px !important;
}
.fundAssetDisbution :global .ant-progress-inner {
  background: #191a23;
}
.kycCertify {
  max-width: 1200px;
  margin: auto;
  /*
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
        height: 40px;
        background: @main-bg-color;
        border-radius: 4px;
        font-size: 16px;
        font-family: @CommonMedium;
        font-weight: 500;
        color: @white-level2;
        line-height: 24px;
        padding: 0 5px;
    }
    .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
        line-height: 40px;
    }
    */
}
.kycCertify .subTile {
  font-size: 16px;
  font-family: Common-Medium;
  font-weight: 500;
  color: #ffffff;
  line-height: 24px;
  margin-bottom: 16px;
}
.kycCertify .btn {
  width: 128px;
  height: 40px;
  background: #F17E00;
  border-radius: 2px;
}
.kycCertify .itemContainer {
  display: grid;
  justify-content: space-between;
  align-items: flex-end;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 40px;
  grid-auto-flow: row;
}
.kycCertify :global .ant-picker {
  width: 100%;
  height: 40px;
  background: #2F3342;
}
.kycCertify :global .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
}
.kycCertify :global .ant-select-single .ant-select-selector .ant-select-selection-item,
.kycCertify :global .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 40px;
  font-size: 14px;
}
.kycCertify :global .ant-select {
  background: #2F3342;
}
.kycCertify .certifyTitle {
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 32px;
  font-family: Common-Medium;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 40px;
}
.kycCertify .certifyContent {
  background: #1F212F;
  padding: 56px 80px;
}
.kycCertify .certifyContent :global .ant-form-item-control-input-content {
  background: transparent;
}
.kycCertify .certifyContent :global .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
  background: #2F3342;
}
.kycCertify :global .ant-form-item-control-input .ant-input {
  padding: 0 16px;
  height: 40px;
}
.kycCertify :global .ant-alert-info {
  height: 40px;
  background: rgba(0, 142, 255, 0.2);
  border-radius: 2px;
  border: 1px solid #008eff;
  margin-bottom: 32px;
}
.kycCertify :global .ant-alert-info .ant-alert-icon {
  color: #008eff;
}
.kycDetail .subTile {
  font-size: 16px;
  font-family: Common-Regular;
  font-weight: 500;
  color: #ffffff;
  line-height: 24px;
}
.kycDetail .kycTable {
  border: 1px solid #4d4e57;
  width: 100%;
  max-height: 757px;
  overflow: auto;
}
.kycDetail .kycTable ::-webkit-scrollbar {
  display: none;
}
.kycDetail .kycTable .item {
  border-bottom: 1px solid #4d4e57;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
}
.kycDetail .kycTable .item:last-child {
  border-bottom: none;
}
.kycDetail .kycTable .item .inner {
  display: grid;
  grid-template-columns: 200px auto;
  width: 100%;
}
.kycDetail .kycTable .label,
.kycDetail .kycTable .value {
  display: flex;
  align-items: center;
  padding: 17px 13px 16px;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.85);
  min-height: 57px;
  word-break: break-all;
}
.kycDetail .kycTable .label {
  background: #2F3342;
}
.kycDetail .submit {
  margin-top: 40px;
}
.kycDetail .back {
  width: 128px;
  margin-right: 16px;
}
.kycDetail :global .ant-result-success .ant-result-icon > .anticon {
  color: #09d687;
}
.automaticEntrance {
  margin: auto;
  background: #1F212F;
  padding: 24px;
  width: 100%;
  width: 1200px;
  /* 防止屏幕过宽显示不好看*/
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  height: 78px;
}
.automaticEntrance .title {
  font-size: 22px;
  font-family: Common-Medium;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 30px;
  margin-bottom: 24px;
}
.automaticEntrance .automaticEntranceBtn {
  border-radius: 20px;
  width: 66px;
  font-family: Common-Medium;
  color: #fff;
  font-size: 16px;
  height: 36px;
}
.addOrRemoveBank p {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 22px;
}
.addOrRemoveBank .accountName {
  font-size: 22px;
  font-family: Common-Medium;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 30px;
  margin-top: 8px;
  margin-bottom: 24px;
}
.addOrRemoveBank .deleteWarning {
  font-size: 16px;
  font-family: Common-Medium;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
  line-height: 24px;
  margin-bottom: 10px;
}
.addOrRemoveBank.isLastOne :global .ant-modal-footer button:first-child {
  display: none;
}
.kycStatus {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  line-height: 22px;
  align-items: center;
  display: flex;
  text-transform: capitalize;
  margin-bottom: 24px;
  cursor: pointer;
}
.kycStatus .certified {
  color: #09d687;
}
.kycStatus .auditing {
  color: #10aeff;
}
.kycStatus .rejected {
  color: #f76260;
}
.statusImg {
  width: 16px !important;
  height: 16px !important;
  margin: 0 5px;
}
.dividerLine {
  width: 100%;
  height: 1px;
  margin: 24px 0;
  background: linear-gradient(180deg, rgba(112, 115, 130, 0) 0%, #3d3f4b 100%);
}
.applicationInfo {
  margin: auto;
  background: #1F212F;
  padding: 24px;
}
.applicationInfo .title {
  font-size: 22px;
  color: rgba(255, 255, 255, 0.85);
}
.applicationInfo .marginBottom24 {
  margin-bottom: 24px;
}
.applicationInfo .accountInfo {
  display: flex;
}
.applicationInfo .accountInfo .key {
  width: 150px;
  margin-right: 100px;
}
.applicationInfo .authorizatioTh {
  height: 48px;
  background-color: rgba(255, 255, 255, 0.05);
  display: flex;
  align-items: center;
  margin: 24px 0;
}
.applicationInfo .authorizatioTh .authorizatioThFirst {
  width: 86px;
  margin: 0 105px 0 16px;
}
.applicationInfo .authorizatioTr {
  display: flex;
}
.applicationInfo .authorizatioTr .authorizatioTrFirst {
  width: 86px;
  margin: 0 105px 0 16px;
  display: flex;
  align-items: center;
}
.applicationInfo .authorizatioTr .authorizatioTrSecond {
  width: 80%;
}
.applicationInfo .periodCheck {
  margin: 17px 0 9px;
}
.agreementContent {
  margin: auto;
  margin-top: 15px;
  background: #1F212F;
  padding: 24px;
}
.agreementContent .title {
  font-size: 22px;
  color: rgba(255, 255, 255, 0.85);
}
.agreementContent .agreementArea {
  padding: 24px;
  height: 600px;
  overflow: auto;
  background-color: #2f3342;
}
.btnContent .btn {
  width: 130px;
  margin-right: 16px;
}
.myReferrals {
  max-width: 1200px;
  margin: auto;
}
.myReferrals .tableWrap {
  display: flex;
  margin-top: 184px;
  position: relative;
}
.myReferrals .tableWrap .myInvitation {
  max-width: 1200px;
  background: #1f212f;
  border-radius: 2px;
  margin: auto;
  margin-top: 34px;
}
.myReferrals .tableWrap .myInvitation .myInvitationArea {
  padding: 25px 24px 32px;
  min-height: 600px;
  font-family: Common-Medium;
  color: rgba(255, 255, 255, 0.85);
}
.myReferrals .tableWrap .myInvitation .myInvitationArea .loadMore {
  display: flex;
  justify-content: center;
  color: #f17e00;
  cursor: pointer;
}
.myReferrals .tableWrap .myInvitation .myInvitationArea .loadMore:hover {
  color: #a45907;
}
.myReferrals .tableWrap .myInvitation .myInvitationArea .table {
  min-height: 600px;
  margin-bottom: 30px;
}
.turnOnServiceModal :global .ant-modal .ant-modal-content {
  padding-right: 28px;
}
.turnOnServiceModal :global .ant-modal .ant-modal-content .ant-modal-body {
  margin-bottom: 40px;
}
.turnOnServiceModal :global .ant-descriptions-bordered .ant-descriptions-view {
  border: 1px solid #4d4e57;
}
.turnOnServiceModal :global .ant-descriptions-bordered .ant-descriptions-row {
  border-bottom: 1px solid #4d4e57;
}
.turnOnServiceModal :global .ant-descriptions-bordered .ant-descriptions-item-label {
  border-right: 1px solid #4d4e57;
}
.turnOnServiceModal :global .ant-descriptions-bordered .ant-descriptions-item-content {
  padding: 6px 9px 10px 12px;
  font-size: 12px;
}
.turnOnServiceModal :global .ant-descriptions-bordered .ant-descriptions-item-label {
  padding: 7px 0 12px 22px;
}
.turnOnServiceModal .serviceModalContent .text {
  color: #fff;
}
