.base {
  background: #1F212F;
  padding: 32px 40px 40px;
  max-width: 1200px;
  margin: auto;
  border-radius: 2px;
}
.hideIcon {
  margin-left: 15px;
  height: 40px;
  font-size: 20px;
  cursor: pointer;
}
.hideIcon:hover {
  color: #F17E00;
}
.wallet {
  max-width: 1200px;
  border-radius: 2px;
  margin: auto;
  margin-top: 34px;
}
.wallet .topTitleArea {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.wallet .topTitleArea .rightTitleArea .allBtn {
  color: #fff;
  width: 140px;
  height: 48px;
  margin-left: 15px;
  font-size: 14px;
  text-align: center;
  padding: 0 !important;
  border: none;
}
.wallet .walletDashboard {
  position: relative;
  max-width: 1200px;
  width: 100%;
  margin: auto auto 16px;
  display: flex;
  justify-content: space-between;
}
.wallet .walletDashboard .dashboard {
  width: 66%;
  height: 120px;
  background: #1F212F;
  display: flex;
  align-items: center;
}
.wallet .walletDashboard .dashboard .item {
  width: 50%;
  text-align: center;
}
.wallet .walletDashboard .dashboard .divider {
  width: 1px;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.05);
}
.wallet .walletDashboard .dashboardIcon {
  width: 40px;
  height: 40px;
  margin-bottom: 6px;
}
.wallet .walletDashboard .number {
  font-size: 36px;
  font-family: Common-Medium;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 30px;
  margin-bottom: 12px;
}
.wallet .walletDashboard .name {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 22px;
}
.wallet .walletDashboard .feeRate {
  width: 33%;
  height: 120px;
  background: #1F212F;
  padding: 28px 24px 0px;
}
.wallet .walletDashboard .feeRate .topArea {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.wallet .walletDashboard .feeRate .topArea .feeRateValue {
  font-size: 22px;
  font-weight: bold;
}
.wallet .walletDashboard .feeRate .dividerJustify {
  margin: 20px 0 17px;
  width: 100%;
  height: 1px;
  background: linear-gradient(180deg, rgba(112, 115, 130, 0.0001) 0%, #3d3f4b 100%);
}
.wallet .walletDashboard .feeRate .bottomArea {
  display: flex;
  justify-content: center;
}
.wallet .walletList {
  position: relative;
  width: 100%;
  background: #26283a;
  margin-bottom: 40px;
}
.wallet .walletList :global .ant-btn {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: #F17E00;
  line-height: 22px;
  border-radius: 2px;
  border: 1px solid #F17E00;
}
.wallet .walletList :global .ant-btn-primary {
  background: #F17E00;
  color: #fff;
  margin-right: 30px;
}
.wallet .walletList :global .ant-btn:hover,
.wallet .walletList :global .ant-btn:active {
  background: #F17E00;
  color: rgba(255, 255, 255, 0.85);
}
.wallet .walletList :global .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  background: transparent;
  pointer-events: none;
}
.wallet .walletList :global .ant-table-thead > tr > th {
  background-color: rgba(255, 255, 255, 0.05);
}
.wallet .walletList .tabArea {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.wallet .walletList .tableFlex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.wallet .walletList .yieldTable .yieldTableTitle {
  display: flex;
  justify-content: space-between;
}
.wallet .walletList .yieldTable .yieldTableTitle :global .ant-radio-group {
  background: rgba(255, 255, 255, 0.0529);
  border-radius: 15px;
}
.wallet .walletList .yieldTable .yieldTableTitle :global .ant-radio-group .ant-radio-button-wrapper {
  width: 142px;
  text-align: center;
  border: none;
  transition: unset;
}
.wallet .walletList .yieldTable .yieldTableTitle :global .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}
.wallet .walletList .yieldTable .yieldTableTitle :global .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #F17E00;
  border-radius: 15px;
  color: #fff;
}
.wallet .walletList .yieldTable .yieldTableTitle :global .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
}
.wallet .walletList .yieldTable .yieldTableTitle :global .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: unset;
}
.wallet .walletList .yieldTable .yieldTableWrapper .upIcon,
.wallet .walletList .yieldTable .yieldTableWrapper .downIcon {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  cursor: pointer;
}
.wallet .walletList .yieldTable .yieldTableWrapper .upIcon {
  border-bottom: 6px solid #F17E00;
}
.wallet .walletList .yieldTable .yieldTableWrapper .downIcon {
  border-top: 6px solid #F17E00;
}
.wallet .walletList .yieldTable .yieldTableWrapper :global .ant-table-row.ant-table-row-level-1 {
  background: #313344;
}
.wallet .walletList .actionBtn,
.wallet .walletList .actionBtn > button {
  margin-right: 16px;
  width: 95px;
  height: 28px;
  padding-top: 0;
  padding-bottom: 0;
}
.wallet .walletList .icon {
  width: 48px;
  height: 48px;
  margin-right: 15px;
  border-radius: 50%;
}
.wallet .walletList .type {
  width: 100%;
  height: 100px;
  background: #1f212f;
  cursor: pointer;
  padding-top: 10px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wallet .walletList .active {
  background: #26283a;
  width: 100%;
  height: 100px;
  cursor: pointer;
  padding-top: 10px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wallet .walletList .divider {
  width: 1px;
  height: 100px;
  background: #1f212f;
  display: flex;
  align-items: center;
}
.wallet .walletList .divider .dividerContent {
  width: 1px;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.05);
}
.wallet .walletList .typeName {
  font-size: 14px;
  font-family: Common-Medium;
  font-weight: 500;
  width: 100%;
  color: rgba(255, 255, 255, 0.85);
  line-height: 22px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.wallet .walletList .typeNumber {
  font-size: 30px;
  font-family: Common-Medium;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 36px;
  margin-bottom: 22px;
}
.wallet .walletList .details {
  font-size: 16px;
  font-family: Common-Medium;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 24px;
  margin-bottom: 24px;
}
.wallet .walletList .loadMore {
  display: flex;
  justify-content: center;
  color: #f17e00;
  cursor: pointer;
  margin-top: 30px;
}
.wallet .walletList .loadMore:hover {
  color: #a45907;
}
.wallet .walletList .noShow {
  display: none;
}
.deposit {
  composes: base;
  background-color: transparent;
  padding: 0;
}
.depositContent {
  composes: base;
  margin: 24px auto 16px;
}
.depositContent .title {
  font-size: 22px;
  font-family: Common-Medium;
  font-weight: 500;
  color: #ffffff;
  line-height: 30px;
  margin-bottom: 16px;
}
.depositContent .label {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 22px;
  margin-bottom: 10px;
}
.depositContent .tips {
  color: #ff5351;
}
.depositContent .QRCode {
  position: relative;
  width: 180px;
  height: 180px;
  background-color: #fff;
  text-align: center;
  padding-top: 5px;
  overflow: hidden;
}
.depositContent .depositLeft {
  border-right: 1px dashed rgba(255, 255, 255, 0.09);
}
.depositContent .depositLeft .primary {
  margin-bottom: 24px;
  width: 80px;
  height: 32px;
  border-radius: 2px;
  border: 1px solid #F17E00;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: #F17E00;
  line-height: 20px;
}
.depositContent .depositLeft .currentCoin {
  margin-bottom: 20px;
  display: flex;
}
.depositContent .depositLeft .currentCoin .selectCoin :global .ant-select-selector {
  border-color: #F17E00 !important;
  color: #F17E00;
}
.depositContent .depositLeft .currentCoin .selectCoin :global .ant-select-arrow {
  color: #F17E00;
}
.depositContent .depositLeft .currentCoin .currentCoinRight {
  margin-left: 60px;
}
.depositContent .depositLeft .currentCoin .currentCoinRight .chainType {
  border: 1px solid #F17E00;
  color: #F17E00;
  width: 80px;
  height: 32px;
  text-align: center;
  border-radius: 2px;
  line-height: 32px;
}
.depositContent .depositLeft .minDepositAmount {
  color: #fff;
  font-size: 24px;
  font-weight: bold;
}
.depositContent .depositLeft .copyArea {
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 14px;
}
.depositContent .depositLeft .copyArea span {
  color: #f17e00;
  position: absolute;
  font-size: 14px;
  right: 12%;
  cursor: pointer;
}
.depositContent .noticeContent {
  background: #2F3342;
  margin-top: 24px;
  padding: 16px;
  color: rgba(255, 255, 255, 0.7);
  line-height: 26px;
  font-family: Common-Regular;
}
.depositContent .noticeContent .title {
  font-size: 14px;
  font-family: Common-Medium;
  font-weight: 500;
  color: #ffffff;
  line-height: 22px;
}
.depositContent :global .ant-input {
  width: 90%;
}
.depositContent :global .anticon svg {
  display: inline-block;
}
.depositContent :global .ant-input[disabled] {
  cursor: pointer;
  font-size: 16px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.85);
  line-height: 24px;
}
.history {
  composes: base;
}
.history .tableTitle {
  margin-bottom: 16px;
  font-size: 18px;
  font-family: Common-Medium;
  font-weight: 500;
  color: #ffffff;
  line-height: 26px;
}
.history .viewAll {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: #F17E00;
  line-height: 22px;
}
.history .loadMore {
  display: flex;
  margin-top: 20px;
  justify-content: center;
  color: #f17e00;
  cursor: pointer;
}
.history .loadMore:hover {
  color: #a45907;
}
.depositFiat .title {
  font-size: 22px;
  font-family: Common-Medium;
  font-weight: 500;
  color: #ffffff;
  line-height: 30px;
  margin-bottom: 16px;
}
.depositFiat .stepOneContent .label {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 22px;
}
.depositFiat .stepOneContent .channels {
  margin-bottom: 28px;
}
.depositFiat .stepOneContent .default {
  width: 80px;
  height: 32px;
  border-radius: 2px;
  border: 1px solid #424555;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 22px;
}
.depositFiat .stepOneContent .primary {
  width: 80px;
  height: 32px;
  border-radius: 2px;
  border: 1px solid #F17E00;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: #F17E00;
  line-height: 20px;
  margin-right: 16px;
}
.depositFiat .stepOneContent .bankDetails {
  padding: 20px 24px;
  width: 560px;
  height: 164px;
  background: #2F3342;
  border-radius: 2px;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.depositFiat .stepOneContent .bankDetails p {
  color: rgba(255, 255, 255, 0.7);
}
.depositFiat .stepOneContent .tipsContent {
  margin: 28px 0;
}
.depositFiat .stepOneContent .tipsContent .tipsTitle {
  font-size: 16px;
  font-family: Common-Medium;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 10px;
}
.depositFiat .stepOneContent .tipsContent .items {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  margin-bottom: 15px;
}
.depositFiat .stepOneContent .btnContent {
  margin: 15px 0;
  height: 36px;
}
.depositFiat .stepOneContent .stepArea {
  width: 100%;
  height: 258px;
  display: flex;
  justify-content: space-between;
}
.depositFiat .stepOneContent .stepArea .stepAreaItem {
  width: 32%;
  text-align: center;
  font-family: Common-Regular;
  font-size: 13px;
  color: #ffffff 70%;
}
.depositFiat .stepOneContent .stepArea .stepAreaItem:first-child {
  background: url("@/assets/images/depositFiatOne.png") no-repeat;
  background-size: 100% 100%;
  padding: 151px 34px 70px;
}
.depositFiat .stepOneContent .stepArea .stepAreaItem:first-child span {
  color: #F17E00;
}
.depositFiat .stepOneContent .stepArea .stepAreaItem:nth-child(2) {
  background: url("@/assets/images/depositFiatTwo.png") no-repeat;
  background-size: 100% 100%;
  padding: 162px 34px 76px;
}
.depositFiat .stepOneContent .stepArea .stepAreaItem:nth-child(3) {
  background: url("@/assets/images/depositFiatThree.png") no-repeat;
  background-size: 100% 100%;
  padding: 125px 34px 0;
}
.depositFiat .stepOneContent .stepArea .stepAreaItem:nth-child(3) .btn {
  margin-top: 24px;
  width: 186px;
  height: 36px;
  text-align: center;
}
.depositFiat .stepTwoContent .label {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 22px;
}
.depositFiat .stepTwoContent .accountArea {
  min-height: 226px;
  display: flex;
  justify-content: space-between;
  padding: 22px;
  background-color: #252734;
  margin-bottom: 30px;
}
.depositFiat .stepTwoContent .accountArea .leftArea {
  width: 50%;
}
.depositFiat .stepTwoContent .accountArea .leftArea .selectCard {
  width: 90%;
  background-color: #2f3342;
}
.depositFiat .stepTwoContent .accountArea .leftArea .selectCard :global(.anticon svg) {
  color: #94979e;
}
.depositFiat .stepTwoContent .accountArea .leftArea .bindCard {
  margin: 8px 0;
  color: #008eff;
  text-decoration: underline;
  cursor: pointer;
}
.depositFiat .stepTwoContent .accountArea .leftArea .inputArea {
  width: 90%;
  height: 40px;
  background-color: #2f3342;
}
.depositFiat .stepTwoContent .accountArea .leftArea .inputArea input::-webkit-outer-spin-button,
.depositFiat .stepTwoContent .accountArea .leftArea .inputArea input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.depositFiat .stepTwoContent .accountArea .leftArea .inputArea input {
  height: 40px;
}
.depositFiat .stepTwoContent .accountArea .middleLine {
  width: 1px;
  opacity: 0.09;
  border: 1px solid #ffffff;
}
.depositFiat .stepTwoContent .accountArea .rightArea {
  width: 50%;
  padding-left: 22px;
}
.depositFiat .stepTwoContent .accountArea .rightArea .bindCardInformationsArea {
  padding: 20px 24px;
  width: 95%;
  height: 154px;
  background: #2F3342;
  border-radius: 2px;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.depositFiat .stepTwoContent .accountArea .rightArea .bindCardInformationsArea p {
  color: rgba(255, 255, 255, 0.7);
  width: 40%;
}
.depositFiat .stepTwoContent .accountArea .rightArea .bindCardInformationsArea .bankData {
  width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.depositFiat .stepTwoContent .uploadContent {
  width: 100%;
  margin: 10px 0 40px;
  display: flex;
}
.depositFiat .stepTwoContent .uploadContent .uploadContainer {
  width: 45%;
  margin-right: 30px;
}
.depositFiat .stepTwoContent .uploadContent .uploadContainer .uploadArea {
  height: 136px;
}
.depositFiat .stepTwoContent .uploadContent .uploadContainer .uploadArea :global(.anticon svg) {
  color: #94979e;
}
.depositFiat .stepTwoContent .uploadContent .rightArea {
  padding-top: 20px;
  color: #94979e;
}
.depositFiat .stepTwoContent .noticeContainer {
  margin-bottom: 30px;
}
.depositFiat .stepTwoContent .noticeContainer .noticeTitle {
  font-size: 16px;
  margin-bottom: 10px;
}
.depositFiat .stepTwoContent .noticeContainer .noticeItem {
  color: #94979e;
  margin-bottom: 5px;
}
.depositFiat .stepTwoContent .btnContent {
  margin: 15px 0;
  width: 93px;
  height: 36px;
}
.modal :global .ant-form-item-control-input .ant-input {
  background: #1F212F;
}
.modal :global .ant-form-item-control-input-content {
  background: transparent;
}
.modal :global .ant-modal-footer {
  border-top: none;
  margin-top: 10px;
  padding-bottom: 0px;
  display: flex;
  justify-content: flex-end;
}
.modal :global .ant-modal-footer button:first-child {
  display: inline-block;
  margin-left: 16px;
  order: 1;
}
.modal :global .ant-modal-footer .ant-btn-primary {
  background: #F17E00 !important;
}
.modal :global .ant-modal-footer .ant-btn-primary:hover {
  background: #ff6500 !important;
}
.modal :global .ant-modal-footer .ant-btn {
  cursor: pointer;
  width: 108px;
  background: #4c5467;
  height: 40px;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: #ffffff;
  line-height: 22px;
  border-radius: 4px;
  margin-top: 20px;
}
.modal :global .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  width: 108px;
}
.modal :global .ant-modal-header > .ant-modal-title {
  color: #fff;
}
.withdraw {
  composes: base;
  background-color: transparent;
  padding: 0;
}
.withdrawContent {
  composes: base;
  margin: 24px auto 16px;
}
.withdrawContent .label {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 10px;
}
.withdrawContent .title {
  font-size: 22px;
  font-family: Common-Medium;
  font-weight: 500;
  color: #ffffff;
  line-height: 30px;
  margin-bottom: 16px;
}
.withdrawContent .noticeTips {
  background: rgba(0, 142, 255, 0.2);
  border: 1px solid rgba(0, 142, 255, 0.3);
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 16px;
  margin-bottom: 32px;
}
.withdrawContent .limitArea {
  height: 40px;
  background: #2f3342;
  border-radius: 2px;
  padding: 9px 16px;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
}
.withdrawContent .limitArea :first-child {
  color: rgba(255, 255, 255, 0.7);
  margin-right: 8px;
}
.withdrawContent .limitArea :nth-child(2) {
  color: #09d687;
  cursor: pointer;
}
.withdrawContent .chainTypeArea {
  margin-top: 30px;
}
.withdrawContent .chainTypeArea .selectCoin :global .ant-select-selector {
  border-color: #F17E00 !important;
  color: #F17E00;
}
.withdrawContent .chainTypeArea .selectCoin :global .ant-select-arrow {
  color: #F17E00;
}
.withdrawContent .chainTypeArea .chainType {
  border: 1px solid #F17E00;
  color: #fff;
  width: 80px;
  height: 32px;
  text-align: center;
  border-radius: 2px;
  line-height: 32px;
}
.withdrawContent .addressArea {
  margin-top: 30px;
  height: 60px;
}
.withdrawContent .addressArea .topArea {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.withdrawContent .addressArea .topArea :nth-child(2) {
  color: #09d687;
  cursor: pointer;
  text-decoration: underline;
}
.withdrawContent .addressArea .addressUsdc {
  height: 40px;
  background: #2f3342;
  border-radius: 2px;
  padding: 9px 16px;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
}
.withdrawContent .addressArea .selectArea {
  width: 100%;
  color: rgba(255, 255, 255, 0.7) !important;
  background-color: #2f3342;
}
.withdrawContent .xrpTag {
  margin: 30px 0;
}
.withdrawContent .xrpTag p {
  margin-bottom: 5px;
}
.withdrawContent .amountArea {
  margin-top: 30px;
  height: 60px;
}
.withdrawContent .amountArea .topArea {
  width: 35%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.withdrawContent .amountArea .topArea :nth-child(2) {
  color: #09d687;
  cursor: pointer;
  text-decoration: underline;
}
.withdrawContent .amountArea .bottomArea .amountTop {
  display: flex;
  align-items: center;
}
.withdrawContent .amountArea .bottomArea .amountTop .amountInput {
  width: 35%;
  background-color: #2f3342;
}
.withdrawContent .amountArea .bottomArea .amountTop .amountInput input::-webkit-outer-spin-button,
.withdrawContent .amountArea .bottomArea .amountTop .amountInput input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.withdrawContent .amountArea .bottomArea .amountTop .limitTips {
  margin-left: 20px;
  color: #a61d24;
  background-color: #1F212F;
  font-size: 14px;
}
.withdrawContent .amountArea .bottomArea .feeTips {
  font-size: 14px;
  color: #fff;
  margin-top: 5px;
}
.withdrawContent .codeArea {
  width: 35%;
  margin-top: 50px;
  margin-bottom: 50px;
  height: 60px;
}
.withdrawContent .codeArea .topArea {
  margin-bottom: 5px;
}
.withdrawContent .codeArea .bottomArea {
  width: 100%;
  display: flex;
}
.withdrawContent .codeArea .bottomArea .leftArea {
  width: 70%;
  margin-right: 20px;
}
.withdrawContent .codeArea .bottomArea .rightArea {
  height: 40px;
  color: #f17e00;
  background-color: #2f3342;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
}
.withdrawContent .confirmBtn {
  margin-top: 60px;
  width: 120px;
  height: 36px;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
}
.withdrawContent .noticeArea {
  margin-top: 50px;
}
.withdrawContent .noticeArea .title {
  font-size: 20px;
  font-family: Common-Medium;
  font-weight: 500;
  color: #ffffff;
}
.withdrawContent .noticeArea .listArea {
  font-family: Common-Medium;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
}
.withdrawContent .noticeArea .listArea li {
  margin-bottom: 10px;
}
.withdrawContent .noticeArea .listArea li span {
  font-weight: 700;
  color: #ffffff;
}
.depositCircle {
  composes: base;
  background-color: transparent;
  padding: 0;
}
.circleContent {
  composes: base;
  margin: 24px auto 16px;
  padding: 38px 80px 93px;
}
.circleContent .title {
  font-family: Common-Medium;
  color: rgba(255, 255, 255, 0.85);
  font-size: 22px;
  margin-bottom: 32px;
}
.circleContent .bankInformation {
  width: 100%;
  margin-bottom: 50px;
}
.circleContent .bankInformation .title {
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 21px;
}
.circleContent .bankInformation .container {
  width: 100%;
  height: 237px;
  padding: 32px 24px 36px;
  background: #252734;
  display: flex;
  justify-content: space-between;
}
.circleContent .bankInformation .container .infoContent {
  width: 45%;
  color: rgba(255, 255, 255, 0.7);
}
.circleContent .bankInformation .container .infoContent .infoContentTitle {
  font-family: Common-Regular;
  font-size: 14px;
  margin-bottom: 8px;
}
.circleContent .bankInformation .container .infoContent .infoContentArea {
  height: 145px;
  background: #2f3342;
  padding: 28px 24px 27px;
}
.circleContent .bankInformation .container .infoContent .infoContentArea p {
  margin-bottom: 14px;
  display: flex;
  justify-content: space-between;
}
.circleContent .bankInformation .container .infoContent .infoContentArea .limitContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}
.circleContent .bankInformation .container .infoContent .infoContentArea .limitContent p {
  display: flex;
  justify-content: right;
  margin-bottom: 0px;
}
.circleContent .bankInformation .container .pin {
  width: 45%;
  color: rgba(255, 255, 255, 0.7);
}
.circleContent .bankInformation .container .pin .pinTitle {
  font-family: Common-Regular;
  font-size: 14px;
  margin-bottom: 8px;
}
.circleContent .bankInformation .container .pin .copyArea {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 10px;
}
.circleContent .bankInformation .container .pin .copyArea .pinNumber {
  width: 100%;
  height: 59px;
  line-height: 59px;
  font-size: 24px;
  font-weight: bold;
  background: #2f3342;
  text-align: center;
}
.circleContent .bankInformation .container .pin .copyArea span {
  color: #f17e00;
  position: absolute;
  font-size: 14px;
  right: 5%;
  cursor: pointer;
}
.circleContent .bankInformation .container .pin .pinNotice {
  font-family: Common-Regular;
  color: #f76260;
}
.circleContent .wiringInformation {
  width: 100%;
  margin-bottom: 50px;
}
.circleContent .wiringInformation .title {
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 21px;
}
.circleContent .wiringInformation .container {
  width: 100%;
  padding: 32px 24px 36px;
  background: #252734;
  color: rgba(255, 255, 255, 0.7);
}
.circleContent .wiringInformation .container .WiringTitle {
  font-family: Common-Regular;
  font-size: 14px;
  margin-bottom: 8px;
}
.circleContent .wiringInformation .container .beneficiary {
  background: #2f3342;
  padding: 24px;
  margin-bottom: 24px;
}
.circleContent .wiringInformation .container .beneficiary p,
.circleContent .wiringInformation .container .beneficiary li {
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
}
.circleContent .wiringInformation .container .beneficiary p:last-child,
.circleContent .wiringInformation .container .beneficiary li:last-child {
  margin-bottom: 0px;
}
.circleContent .wiringInformation .container .instructionsTitle {
  font-size: 14px;
  color: #fff;
  margin-bottom: 15px;
}
.circleContent .wiringInformation .container .instructions {
  font-size: 14px;
  color: #fff;
}
.circleContent .addContent :global .ant-picker {
  width: 100%;
  height: 40px;
  background: #2F3342;
}
.circleContent .addContent :global .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
}
.circleContent .addContent :global .ant-select-single .ant-select-selector .ant-select-selection-item,
.circleContent .addContent :global .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 40px;
  font-size: 14px;
}
.circleContent .addContent :global .ant-select {
  background: #2F3342;
}
.circleContent .addContent .title {
  font-family: Common-Medium;
  color: rgba(255, 255, 255);
  font-size: 16px;
  margin-bottom: 16px;
}
.circleContent .addContent .infoCotnet {
  margin-bottom: 26px;
}
.circleContent .addContent .infoCotnet .itemsContent {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
}
.circleContent .addContent .infoCotnet .itemsContent .itemContent {
  width: 48%;
}
.circleContent .addContent .infoCotnet .itemsContent .itemContent .itemTitle {
  margin-bottom: 8px;
  font-family: Common-Regular;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
}
.circleContent .addContent .infoCotnet .itemsContent .itemContent .itemArea {
  width: 100%;
  height: 40px;
  background-color: #2f3342;
  padding: 9px 16px;
  color: rgba(255, 255, 255, 0.7);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.circleContent .addContent .uploadContent {
  width: 100%;
  display: flex;
  margin-bottom: 50px;
}
.circleContent .addContent .uploadContent .uploadContainer {
  width: 33%;
  height: 72px;
  margin-right: 36px;
  white-space: nowrap;
}
.circleContent .addContent .uploadContent .rightArea {
  color: #fff;
  font-size: 14px;
}
.AUMFee {
  margin-top: 184px;
  position: relative;
}
.AUMFee .assetContent {
  margin-bottom: 16px;
  width: 100%;
  height: 120px;
  background-color: #1F212F;
  padding: 25px 0;
}
.AUMFee .assetContent .assetItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.AUMFee .assetContent .assetItem .assetValue {
  margin-bottom: 12px;
  font-size: 36px;
  font-weight: bold;
  color: #fff;
}
.AUMFee .assetContent .assetItem .assetText {
  color: rgba(255, 255, 255, 0.7);
}
.AUMFee .assetContent .assetItemMiddle {
  border-left: 1px solid #393c54;
  border-right: 1px solid #393c54;
}
.AUMFee .tableArea {
  background-color: #1F212F;
  padding: 30px 24px 32px;
  min-height: 600px;
}
.AUMFee .loadMore {
  display: flex;
  justify-content: center;
  color: #f17e00;
  cursor: pointer;
}
.AUMFee .loadMore:hover {
  color: #a45907;
}
.AUMFee .table {
  margin-bottom: 30px;
}
.AUMModal p {
  color: #fff;
  margin-bottom: 20px;
}
.depositOrWithdrawUSDCModal :global .ant-modal-body .btn-actions {
  display: flex;
  justify-content: center;
  margin-top: 48px;
}
.depositOrWithdrawUSDCModal :global .ant-modal-body .btn-actions .ant-btn {
  height: 36px;
}
.fixedYieldDetailModal .fixedTypeTitle {
  width: 100%;
  display: flex;
  height: 26px;
  color: #fff;
}
.fixedYieldDetailModal .fixedTypeTitle > div {
  text-align: center;
}
.fixedYieldDetailModal .fixedTypeTitle div.subscription {
  width: 30%;
  background: #07c160;
}
.fixedYieldDetailModal .fixedTypeTitle div.lockedUp {
  background: #fa5151;
  flex: 1 1;
}
.fixedYieldDetailModal .fixedTypeTitle div.interests {
  width: 30%;
  background: #f17e00;
}
.fixedYieldDetailModal .fixedTypeContent {
  display: flex;
  justify-content: center;
  background: #26283a;
  padding: 16px 0;
  font-size: 12px;
}
.fixedYieldDetailModal .fixedTypeContent .subscriptionDetail,
.fixedYieldDetailModal .fixedTypeContent .interestsDetail {
  width: 31%;
}
.fixedYieldDetailModal .fixedTypeContent .subscriptionDetail {
  padding-left: 15px;
}
.fixedYieldDetailModal .fixedTypeContent .lockedUpDetail {
  flex: 1 1;
  padding-left: 10px;
}
.fixedYieldDetailModal .fixedTypeContent .interestsDetail {
  padding-left: 10px;
}
.fixedYieldDetailModal .descriptionItems .detailItem {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-top: 16px;
}
.fixedYieldDetailModal .descriptionItems .detailItem .itemKey {
  color: rgba(255, 255, 255, 0.6047);
}
.fixedYieldDetailModal .descriptionItems .detailItem .itemValue {
  color: #fff;
  text-align: right;
}
.fixedYieldDetailModal .descriptionItems .secondItems {
  margin-bottom: 20px;
}
.fixedYieldDetailModal .descriptionItems .thirdItems {
  margin-top: 16px;
  margin-bottom: 26px;
}
.fixedYieldDetailModal .descriptionItems .apySteps {
  margin: 22px 0;
}
.fixedYieldDetailModal .descriptionItems .apySteps .apyStepTableFlex {
  margin-top: 14px;
  font-size: 12px;
  display: flex;
}
.fixedYieldDetailModal .descriptionItems .apySteps .apyStepTableFlex .apyStepTableFlexTitle :global .ant-row {
  width: 130px;
  height: 36px;
  align-items: center;
  padding-left: 10px;
  border-right: 1px solid #2f3342;
}
.fixedYieldDetailModal .descriptionItems .apySteps .apyStepTableFlex .apyStepTableFlexTitle :global .ant-row:nth-child(odd) {
  background: #26283a;
}
.fixedYieldDetailModal .descriptionItems .apySteps .apyStepTableFlex .apyStepTableFlexContent {
  width: calc(100% - 130px);
  color: #fff;
  overflow-x: scroll;
  /* 设置滚动条的样式 */
}
.fixedYieldDetailModal .descriptionItems .apySteps .apyStepTableFlex .apyStepTableFlexContent::-webkit-scrollbar {
  height: 3px;
}
.fixedYieldDetailModal .descriptionItems .apySteps .apyStepTableFlex .apyStepTableFlexContent :global .ant-row {
  flex-wrap: nowrap;
  height: 36px;
  align-items: center;
}
.fixedYieldDetailModal .descriptionItems .apySteps .apyStepTableFlex .apyStepTableFlexContent :global .ant-row > .ant-col {
  flex: 1 1;
  flex-basis: 102px;
  flex-shrink: 0;
  text-align: center;
  line-height: 36px;
}
.fixedYieldDetailModal .descriptionItems .apySteps .apyStepTableFlex .apyStepTableFlexContent :global .ant-row > .ant-col:not(:first-child) {
  border-left: 1px solid #2f3342;
}
.fixedYieldDetailModal .descriptionItems .apySteps .apyStepTableFlex .apyStepTableFlexContent :global .ant-row:nth-child(odd) > .ant-col {
  background: #26283a;
}
.fixedYieldDetailModal .descriptionItems .apySteps .apyStepTableFlex .apyStepTableFlexContent :global .ant-row:last-child > .ant-col {
  border-left: unset;
}
.fixedYieldDetailModal .checkbox {
  display: flex;
  align-items: center;
}
.fixedYieldDetailModal .checkbox .checkboxText {
  color: #fff;
  margin-left: 16px;
}
