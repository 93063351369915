:global .nc-container {
  position: static !important;
  font-size: 14px !important;
}
:global .nc-container .nc_wrapper {
  width: 100% !important;
  height: 56px !important;
  font-size: 14px !important;
  line-height: 56px !important;
}
:global .nc-container .nc_wrapper .nc_scale {
  height: 100%;
  background-color: #41444e;
}
:global .nc-container .nc_wrapper .nc_scale .btn_slide {
  color: #fff;
  font-family: unset !important;
  background-image: url("../../assets/images/doubleArrow.png") !important;
  background-size: 14px !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  z-index: 9999 !important;
}
:global .nc-container .nc_wrapper .nc_scale .nc_bg {
  background-color: #21bf73;
}
:global .nc-container .nc_wrapper .nc_scale .nc_iconfont.btn_ok {
  color: #21bf73;
  border: 1px solid #21bf73;
}
:global .nc-container .nc_wrapper .nc_scale .scale_text.slidetounlock {
  color: #fff;
  line-height: 56px;
}
:global .nc-container .nc_wrapper .nc_scale .scale_text.slidetounlock span[data-nc-lang="SLIDE"] {
  background: none;
  background-clip: unset;
  -webkit-text-fill-color: unset;
}
:global .nc-container .nc_wrapper .nc_scale .scale_text2.slidetounlock {
  color: #fff;
}
:global .nc-container .nc_wrapper .nc_scale span {
  width: 90px;
  height: 100%;
  line-height: 56px;
  border: none;
  background: #1b2131;
  border: 1px solid #41444e;
}
:global .nc-container .nc_wrapper .errloading {
  border: none !important;
  background: #fa5151 !important;
  line-height: 36px !important;
  cursor: pointer;
  color: #fff !important;
}
:global .nc-container .nc_wrapper .errloading .icon_warn {
  display: none;
}
