.wordEllipsis {
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.intelligence {
  min-height: 760px;
}
.intelligence .dailyBrifing {
  margin-top: 24px;
}
.intelligence .dailyBrifingTitle {
  height: 56px;
  background-color: #1F212F;
  border-bottom: 1px solid #2f3342;
  padding: 16px 32px;
  margin-right: 16px;
  font-size: 24px;
  font-weight: 700;
}
.intelligence .articleList {
  background: #1F212F;
  padding: 16px 32px 40px;
  margin-right: 16px;
  position: relative;
  min-height: 751px;
}
.intelligence .articleList .articlePicture {
  margin-right: 24px;
}
.intelligence .articleList .articleOthers {
  line-height: 24px;
  display: flex;
  align-items: center;
}
.intelligence .articleList .type {
  height: 24px;
  border-radius: 2px;
  border: 1px solid #F17E00;
  padding: 1px 8px;
  font-size: 12px;
  font-family: Common-Regular;
  font-weight: 400;
  color: #F17E00;
  line-height: 20px;
  margin-right: 16px;
}
.intelligence .articleList .type span {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.intelligence .articleList .divider {
  width: 1px;
  height: 12px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.intelligence .articleList .auth {
  font-size: 12px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 20px;
  margin-left: 16px;
  margin-right: 16px;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.intelligence .articleList .date {
  font-size: 12px;
  font-family: Common-Regular;
  font-weight: 400;
  margin-left: 16px;
  color: rgba(255, 255, 255, 0.7);
  line-height: 20px;
}
.intelligence .noticeList {
  background: #1F212F;
  min-height: 807px;
}
.intelligence .noticeList .topContent {
  margin: 0 0 20px;
  padding: 0 24px;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #2f3342;
}
.intelligence .noticeList .topContent .title {
  font-size: 16px;
}
.intelligence .noticeList .topContent .reloadList {
  font-size: 20px;
  cursor: pointer;
}
.intelligence .noticeList .topContent .reloadList :hover {
  color: #F17E00;
}
.intelligence .noticeList .scrollArea {
  height: 731px;
  overflow: auto;
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;
}
.intelligence .noticeList .scrollArea .noticeContainer {
  padding: 0 24px 16px;
}
.intelligence .noticeList .scrollArea :global .infinite-scroll-component {
  padding-top: 10px;
}
.intelligence .noticeList .noticeDate {
  display: inline-block;
  padding: 1px 6px;
  margin-left: 0;
  background: #2f3342;
  border-radius: 2px;
  font-size: 12px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 20px;
  margin-bottom: 13px;
}
.intelligence .noticeList .noticeTitle {
  font-size: 14px;
  font-family: Common-Medium;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 22px;
  margin-bottom: 6px;
  max-width: 340px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.intelligence .noticeList .loadAll {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: #f17e00;
  line-height: 22px;
}
.intelligence .noticeList .finish :global .ant-timeline-item-head {
  background-color: #ea2f2f;
}
.intelligence .noticeList :global {
  /*
            .ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot, .ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot,.ant-steps-dot .ant-steps-item-icon, .ant-steps-dot.ant-steps-small .ant-steps-item-icon{
                width: 7px;
                height: 7px;
                top:0;
            }
            .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after{
                width: 1px;
                border: 1px dashed @white-level7;
                background: transparent;
            }
            .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot{
                background: #606578;
                left: 0;
            }
            .clicked .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot{
                background: #EA2F2F;
            }
            .ant-steps-vertical > .ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-tail::after{
                height: 80px;
            }
            */
}
.intelligence .noticeList :global .ant-timeline-item {
  cursor: pointer;
}
.intelligence .noticeList :global .ant-timeline-item-head {
  width: 7px;
  height: 7px;
  border: none;
  background-color: #606578;
}
.intelligence .noticeList :global .ant-timeline-item-tail {
  width: 1px;
  border: 1px dashed rgba(255, 255, 255, 0.1);
  background: transparent;
  left: 3px;
}
.intelligence .noticeList :global .ant-timeline-item-last > .ant-timeline-item-tail {
  display: block;
}
.intelligence .noticeList :global .ant-timeline-item-last .ant-timeline-item-tail {
  height: 90px;
}
.intelligence .row {
  flex-flow: row nowrap;
}
.intelligence .tabDivider {
  width: 100%;
  height: 1px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  position: absolute;
  top: 56px;
  left: 0;
}
.intelligence .itemDivider {
  width: 100%;
  height: 1px;
  margin: 24px 0;
  border: 1px dashed rgba(255, 255, 255, 0.1);
}
.intelligence .dailyBrifing :global .ant-tabs {
  margin-bottom: 16px;
}
.intelligence .dailyBrifing :global .ant-tabs-tab-btn {
  margin: auto;
}
.intelligence .dailyBrifing :global .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-size: 16px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.85);
  line-height: 24px;
}
.intelligence .dailyBrifing :global .ant-tabs-tab {
  padding: 1px 0;
  width: 128px;
  height: 40px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  font-size: 16px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.85);
  line-height: 24px;
  text-align: center;
}
.intelligence .dailyBrifing :global .ant-tabs-tab-active {
  background: #f17e00;
}
.intelligence .dailyBrifing :global .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  display: block;
}
.intelligence :global {
  /* 分页*/
}
.intelligence :global .ant-pagination-item {
  border: none;
  width: 32px;
  height: 32px;
  background: #2F3342;
  border-radius: 2px;
  margin-right: 10px;
}
.intelligence :global .ant-pagination-item a {
  font-size: 14px;
  line-height: 32px;
  font-family: HelveticaNeue;
  color: #ffffff;
}
.intelligence :global .ant-pagination-item-active {
  background: #F17E00;
}
.articleContainer {
  align-items: center;
  height: 150px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}
.articleTitle {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  font-family: Common-Regular;
  /* Compose 组合样式 */
  composes: wordEllipsis;
}
.articleContent {
  font-family: Common-Medium;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7) !important;
  /* Compose 组合样式 */
  composes: wordEllipsis;
}
.articleContent span {
  color: rgba(255, 255, 255, 0.7) !important;
  background-color: #1f212f !important;
  font-size: 14px !important;
}
.articleContent img {
  display: none !important;
}
.noticeBrief {
  font-size: 13px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 20px;
  /* Compose 组合样式 */
  composes: wordEllipsis;
}
.detail {
  /* background: @module-bg; */
  max-width: 1200px;
  margin: auto;
  padding: 16px 32px 40px;
}
.detail h1,
.detail h2,
.detail h3,
.detail h4 {
  line-height: 40px !important;
}
.detail .image {
  margin-bottom: 30px;
}
.detail .title {
  font-size: 36px;
  font-weight: 700;
  font-family: Common-Medium;
  margin-bottom: 30px;
  line-height: normal;
}
.detail .articleOthers {
  margin-bottom: 30px;
  line-height: 24px;
  display: flex;
  align-items: center;
}
.detail .articleOthers .type {
  height: 24px;
  border-radius: 2px;
  border: 1px solid #F17E00;
  padding: 1px 8px;
  font-size: 12px;
  font-family: Common-Regular;
  font-weight: 400;
  color: #F17E00;
  line-height: 20px;
  cursor: auto;
}
.detail .articleOthers .type span {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.detail .articleOthers .divider {
  width: 1px;
  height: 12px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  margin: 0 16px;
}
.detail .articleOthers .auth {
  font-size: 12px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 20px;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.detail .articleOthers .date {
  font-size: 12px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 20px;
}
.detail .articleContents {
  font-size: 16px;
  margin-bottom: 30px;
}
.detail .articleContents p {
  text-indent: 0px;
}
.marketSingal {
  margin-top: 24px;
}
.marketSingal .left {
  width: 240px;
  float: left;
  height: 100%;
}
.marketSingal .left img {
  width: 16px;
  height: 16px;
}
.marketSingal .right {
  margin-left: 280px;
}
.marketSingal .exchangeFlows {
  display: flex;
  flex-wrap: wrap;
}
.marketSingal .exchangeFlows .flow {
  height: 412px;
  width: 45%;
  background: #1f212f;
  border-radius: 2px;
  padding: 20px;
  margin-right: 40px;
  margin-bottom: 20px;
}
.marketSingal .exchangeFlows .flow p {
  font-size: 12px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 18px;
}
.marketSingal .exchangeFlows .flow .title {
  font-size: 16px;
  font-family: Common-Medium;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 24px;
  margin-bottom: 4px;
}
.marketSingal .exchangeFlows .flow .switchButton {
  width: 58px;
  height: 28px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 22px;
  margin-right: 16px;
  padding: 0;
}
.marketSingal .exchangeFlows .flow .active {
  background: #F17E00;
  color: rgba(255, 255, 255, 0.85);
}
.marketSingal .exchangeFlows :global .ant-image {
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-right: 10px;
}
.marketSingal :global .ant-menu-root {
  margin-right: 16px;
  background: #1f212f;
  border-radius: 2px;
  margin: 0 24px;
  padding-top: 24px;
  height: 90vh;
}
.marketSingal :global .ant-menu-sub.ant-menu-inline {
  background: transparent;
}
.marketSingal :global .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #f17e00;
  border-radius: 2px;
}
.marketSingal :global .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected .ant-menu-title-content {
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: #ffffff !important;
  line-height: 22px;
}
.marketSingal :global .ant-menu-inline .ant-menu-selected::after,
.marketSingal :global .ant-menu-inline .ant-menu-item-selected::after {
  border-right: 0;
}
.marketSingal :global .ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
.marketSingal :global .ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
  padding-left: 5px;
  font-size: 14px;
  font-family: Common-Regular;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  line-height: 22px;
}
